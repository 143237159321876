import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import { getEditFieldLot } from "@api/auctions";
import { auctionStore } from "@mobx/auction";

import { Lot } from "./lots/lot";
import { LotForm } from "./lots/lot-form";

import "./add-auction-lots.scss";

export const AddAuctionLots = observer((props) => {
	const { edit } = props;
	const { auction } = auctionStore;
	const [lots, setLots] = useState(auction?.lots || []);
	const [lotSuccess, setLotSuccess] = useState("");
	const [editId, setEditId] = useState();
	const [showLotForm, setShowLotForm] = useState(false);
	const [editFields, setEditFields] = useState({});
	const [hasChanges, setHasChanges] = useState(false);
	const [isAuctionUp, setIsAuctionUp] = useState(
		auction.auction_type === "parallel_increase"
	);

	useEffect(() => {
		auction?.id &&
		getEditFieldLot(Number(auction.id), "update").then((resp) =>
			setEditFields(resp)
		);
	}, []);

	const hideNewLot = () => {
		setShowLotForm(false);
	};

	const onCreateLot = (newLots) => {
		setShowLotForm(false);
		setLots(newLots);
		setLotSuccess("Лот успешно добавлен");
		setHasChanges(true);

		setTimeout(() => setLotSuccess(""), 5000);
	};

	const onSaveLot = (newLots) => {
		setEditId(null);
		setLots(newLots);
		setLotSuccess("Лот успешно изменен");
		setHasChanges(true);

		setTimeout(() => setLotSuccess(""), 5000);
	};

	const onRemoveLot = (id) => {
		setLots((prev) => {
			const result = prev.filter((item) => item.id !== id);

			if (!result.length) {
				setShowLotForm(true);
			}

			return result;
		});

		setLotSuccess("Лот успешно удален");
		setHasChanges(true);

		setTimeout(() => setLotSuccess(""), 5000);
	};

	const onEditLot = (lotId) => {
		setEditId(lotId);
	};

	const onCancelLotEdit = () => {
		setEditId(null);
	};

	const onStepChange = (isBack) => {
		if (hasChanges) {
			auctionStore.refetch();
		}

		props.onStepChange(isBack);
	};

	return (
		<>
			<div className="auction-lots">
				{(!lots?.length || showLotForm) && auction && (
					<>
						<LotForm
							typeAuction={auction.auction_type}
							onClose={hideNewLot}
							onChange={onCreateLot}
							auctionId={auction?.id}
							hideClose={!lots?.length}
							order={lots.length}
							isNewLot={true}
							isEditAdmin={edit}
							expiresAt={
								auction?.auction_type === "parallel_increase"
									? lots[0]?.expires_at
									: undefined
							}
							editFields={editFields}
						/>
						{Boolean(lotSuccess) && (
							<div className="mt-2 mb-2 alert alert-success">
								{lotSuccess}
							</div>
						)}
					</>
				)}
				{lots?.length > 0 && (
					<div>
						{lots?.map((lot) => {
							if (editId === lot.id) {
								return (
									<LotForm
										key={`edit-lot-${lot.id}`}
										typeAuction={auction?.auction_type}
										onClose={onCancelLotEdit}
										onChange={onSaveLot}
										auctionId={auction?.id}
										isEditAdmin={edit}
										isEdit={edit}
										lot={lot}
										editFields={editFields}
										lotLength={lots?.length}
									/>
								);
							}

							return (
								<Lot
									edit={edit}
									key={`saved-lot-${lot.id}`}
									lot={lot}
									auctionId={auction?.id}
									typeAuction={auction?.auction_type}
									auctionStatus={auction?.status?.code}
									lotLength={lots?.length}
									onRemove={onRemoveLot}
									onEdit={onEditLot}
								/>
							);
						})}
						{lots.length > 0 &&
							(auction?.status?.code === "auction_new" ||
								auction?.status?.code === "auction_active" || auction?.status?.code === "auction_not_active") &&
							isAuctionUp && (
								<button
									onClick={() => setShowLotForm(true)}
									className="btn btn-primary d-flex align-items-center gap-5px mt-16px"
									type="button"
								>
									<img
										src="/img/icons/plus-icon.svg"
										alt=""
									/>
									Добавить лот
								</button>
							)}
					</div>
				)}
			</div>
			<div className="btn-footer mt-30px auction-buttons-footer">
				<button
					type="button"
					className="btn btn-secondary d-flex align-items-center gap-10px"
					onClick={() => onStepChange(true)}
				>
					<img src="/img/icons/arrow-left-icon.svg" alt="" />
					Назад
				</button>
				<button
					type="button"
					className="btn btn-primary d-flex align-items-center gap-10px"
					onClick={() => onStepChange()}
				>
					{edit ? "Редактировать" : "Настроить"} доступ
					<img src="/img/icons/arrow-right-icon.svg" alt="" />
				</button>
			</div>
		</>
	);
});
