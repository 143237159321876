import Echo from "laravel-echo";

import { FORCE_TLS, PUSHER_KEY, WSS_PORT, WS_HOST, WS_PORT } from "@api/config";
import { SocketRequest } from "@api/socket-request";
import { auctionsStore } from "@mobx/auctions";

import { exchangeStore } from "./exchange";

class PusherStore {
	connect() {
		// eslint-disable-next-line global-require
		window.Pusher = require("pusher-js");

		window.Echo = new Echo({
			broadcaster: "pusher",
			key: PUSHER_KEY,
			wsHost: WS_HOST,
			wsPort: WS_PORT,
			wssPort: WSS_PORT,
			enabledTransports: ["ws", "wss"],
			forceTLS: FORCE_TLS,
			disableStats: true,
			authorizer: (channel) => {
				return {
					authorize: (socketId, callback) => {
						SocketRequest("/api/broadcasting/auth", "POST", {
							socket_id: socketId,
							channel_name: channel.name
						})
							.then((response) => {
								callback(false, response);
							})
							.catch((error) => {
								callback(true, error);
							});
					}
				};
			}
		});
	}

	subscribeAuctions = (props) => {
		const { userId, onAuctionPublished } = props;

		this.connect();

		window.Echo.private("App.Models.Auction").listen(
			"BetPlaced",
			(data) => {
				auctionsStore.placeBet(data);
			}
		);

		window.Echo.private("App.Models.Auction").listen(
			"BetCanceled",
			(data) => {
				auctionsStore.cancelBet(data);
			}
		);

		window.Echo.private("App.Models.Auction").listen(
			"LotEndingWasChanged",
			(data) => {
				auctionsStore.changeLotEnding(data);
			}
		);

		window.Echo.private(`App.Models.User.${userId}`).notification(
			(notification) => {
				auctionsStore.setBetNotification(notification || null);
			}
		);

		window.Echo.private("App.Models.Auction").listen(
			"LotDecreased",
			(data) => {
				auctionsStore.decreaseLot(data);
			}
		);

		window.Echo.private("App.Models.Auction").listen(
			"LotFinished",
			(data) => {
				auctionsStore.finishLot(data);
			}
		);

		window.Echo.private("App.Models.Auction").listen(
			"LotStarted",
			(data) => {
				auctionsStore.startLot(data);
				auctionsStore.refetchAuction(data.auction_id);
			}
		);

		window.Echo.private("App.Models.Auction").listen(
			"AuctionWasChanged",
			(data) => {
				auctionsStore.refetchAuction(data.auction_id);
			}
		);

		window.Echo.private("App.Models.Auction").listen(
			"AuctionEnded",
			(data) => {
				auctionsStore.refetchAuction(data.auction_id);
			}
		);

		window.Echo.private("App.Models.Auction").listen(
			"AuctionPublished",
			(data) => {
				if (onAuctionPublished) onAuctionPublished();
			}
		);
	};

	subscribeExchange = () => {
		this.connect();

		window.Echo.private("App.Models.ExchangeOffer").listen(
			"ExchangeOfferLotReserved",
			(data) => {
				exchangeStore.setLotReserved(data.lot_id);
			}
		);
	};

	disconnect() {
		window.Echo?.disconnect();
	}
}

export const pusherStore = new PusherStore();
