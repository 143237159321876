import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { Table } from "@components/table/table";
import { Pagination } from "@components/pagination/pagination";
import { filteredUsers, getUsersList } from "@api/users";
import { USERS_COLUMNS } from "@pages/auction/auction-edit-trades-result-modal/utils";
import { citiesStore } from "@mobx/cities";
import { Input } from "@components/input/input";
import { Select } from "@components/select/select";
import { Button } from "@components/button/button";

const PER_PAGE = [25, 50, 100, 250, 500];

const DEFAULT_FILTERS = {
	"filters[middle_name]": "",
	"filters[first_name]": "",
	"filters[last_name]": "",
	"filters[city_ids][]": ""
};

export const UsersTable = observer(({ onSelectUser }) => {
	const { cities } = citiesStore;

	const [users, setUsers] = useState();
	const [filters, setFilters] = useState(DEFAULT_FILTERS);

	const requestUsers = (params) => {
		getUsersList(params).then(setUsers);
	};

	useEffect(() => {
		requestUsers({ page: 1, per_page: PER_PAGE[0] });

		if (!citiesStore.cities.length) {
			citiesStore.fetch();
		}
	}, []);

	const getFilteredUsersList = () => {
		let data = {};
		Object.entries(filters).forEach(([key, value]) => {
			if (value) data[key] = value;
		});
		filteredUsers(data, { page: 1, per_page: users?.per_page }).then(setUsers);
	};

	const clearAllFilters = () => {
		setFilters(DEFAULT_FILTERS);
		requestUsers({ page: 1, per_page: users?.per_page });
	};

	const handleInputChange = (e) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value
		});
	};

	return (
		<>
			<div className="user-filters">
				<div className="d-flex align-items-center flex-wrap gap-10px">
					<Input
						size="s"
						placeholder="Фамилия"
						name="filters[last_name]"
						value={filters["filters[last_name]"]}
						onChange={handleInputChange}
					/>
					<Input
						size="s"
						placeholder="Имя"
						name="filters[first_name]"
						value={filters["filters[first_name]"]}
						onChange={handleInputChange}
					/>
					<Input
						size="s"
						placeholder="Отчество"
						name="filters[middle_name]"
						value={filters["filters[middle_name]"]}
						onChange={handleInputChange}
					/>
					<Select
						options={cities?.map((city) => ({ value: city.id, label: city.name }))}
						valueId={filters["filters[city_ids][]"]}
						size="s"
						name="city_id"
						placeholder="Город"
						onChange={({ value }) => handleInputChange({ target: { name: "filters[city_ids][]", value } })}
					/>
				</div>
				<div className="d-flex mt-20px gap-15px">
					<Button onClick={getFilteredUsersList} size="s">
						Применить фильтры
					</Button>
					<Button onClick={clearAllFilters} className="d-flex align-items-center gap-5px" preset="secondary"
							size="s">
						<img src="/img/icons/filters-off-icon.svg" />
						Очистить
					</Button>
				</div>
			</div>
			{users?.data?.length > 0 ? (
				<>
					<div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
						<Table
							data={users?.data}
							columns={USERS_COLUMNS}
							rowEvents={{
								onClick: (e, row) => {
									onSelectUser?.(row, "user");
								}
							}}
						/>
					</div>
					<div className="pagination-modal-wrapper">
						<Pagination
							data={users}
							onChange={requestUsers}
							perPageList={PER_PAGE}
						/>
					</div>
				</>
			) : (
				<p>Нет данных</p>
			)}
		</>
	);
});
