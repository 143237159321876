import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";

import { AuctionItem } from "@components/auction-item/auction-item";
import { LotChangeView } from "@components/lot-change-view/lot-change-view";
import { Pagination } from "@components/pagination/pagination";
import useMediaQuery from "@hooks/use-media-query";
import { auctionsStore } from "@mobx/auctions";
import { meStore } from "@mobx/me";
import { pusherStore } from "@mobx/pusher";
import { NO_PERMISSION_URL, SELLER_URL } from "@utils/common";
import { VIEW_HORIZONTAL, VIEW_TILE } from "./current-trades-filters/utils";

import Filter from "@components/filters/Filter/Filter";
import FilterButton from "@components/filters/FilterButton/FilterButton";
import useTradesFilter from "@hooks/filters/auctions/useTradesFilter";
import "./current-trades.scss";
import Separator from "@components/utils/Separator/Separator";

const PER_PAGE_LIST = [25, 50, 100, 250];

export const CurrentTrades = observer(
	({ endpointUrl, disableAddButton, tabModifiers }) => {
		const { ready, items, itemsAdditional, setDefaultItems, loadCarData } =
			useTradesFilter();
		const [filterShow, setFilterShow] = useState(false);

		const [isReadyFilter, setIsReadyFilter] = useState(false);
		const [formData, setFormData] = useState({});

		const [perPage, setPerPage] = useState(25);

		const { auctions } = auctionsStore;
		const user = meStore.data;
		const endPoint = endpointUrl ?? null;

		const [searchParams, setSearchParams] = useSearchParams();
		const [isInitLoading, setIsInitLoading] = useState(false);
		const isMobile = useMediaQuery();

		const [auction, setAuction] = useState(null);
		const $ref = useRef();
		const [fixedAuctionHeader, setFixedAuctionHeader] = useState();
		const [view, setView] = useState();

		useEffect(() => {
			setView(
				isMobile
					? VIEW_TILE
					: localStorage.getItem("auction_view") || VIEW_HORIZONTAL
			);
		}, [isMobile]);

		const onScrollContainer = () => {
			const containerScrollTop = window.scrollY + 50;
			let newFixedElem;

			// eslint-disable-next-line guard-for-in
			for (const key in $ref.current?.children) {
				const elem = $ref.current?.children[key];

				if (
					elem.classList?.contains("card-auction") &&
					containerScrollTop >= elem.offsetTop
				) {
					newFixedElem = elem.id;
				}
			}

			if (fixedAuctionHeader !== Number(newFixedElem)) {
				setFixedAuctionHeader(Number(newFixedElem));
			}
		};

		useEffect(() => {
			window.addEventListener("scroll", onScrollContainer);

			return () => {
				window.removeEventListener("scroll", onScrollContainer);
			};
		}, []);

		// requestAuctions({ page: 1, per_page: per_page }, formData);
		const requestAuctions = (params, filters) => {
			return auctionsStore.getAuctions(
				{
					"filters[status_codes]": [
						"auction_active",
						"auction_not_active"
					],
					per_page: params?.per_page || auctions.per_page,
					page: params?.page || auctions.page,
					"orders[planned_finish_at]": "asc",
					...(params || {})
				},
				endPoint,
				filters
			);
		};

		useEffect(() => {
			if (user.user?.id) {
				pusherStore.subscribeAuctions({
					userId: user.user.id,
					onAuctionPublished: () => {
						requestAuctions({ page: 1, per_page: perPage }, formData);
					}
				});
			}

			return () => {
				pusherStore.disconnect();
			};
		}, [user.user?.id]);

		useEffect(() => {
			if (ready) {
				let auctionId = searchParams.get("auctionId");
				if (auctionId) {
					sessionStorage.removeItem("filter:trades");
					sessionStorage.removeItem("filter:trades:data");
					sessionStorage.setItem(
						"filter:trades",
						JSON.stringify({ "filters[ids][]": auctionId })
					);
					sessionStorage.setItem("filter:trades:data", "{}");
				}
				setIsReadyFilter(true);
			}
		}, [ready]);

		const changeSelectFilter = async (key, type, data, reset) => {
			loadCarData(key, type, data, "filter:trades:data", reset);
		};

		const onInitSelectField = async (key, type, data) => {
			changeSelectFilter(key, type, data);
		};

		const onChangeSelectField = async (key, type, data) => {
			changeSelectFilter(key, type, data, true);
		};

		const onSubmit = (data) => {
			setFormData(data);
			if (isInitLoading) setSearchParams({});
			requestAuctions({ page: !isInitLoading ? searchParams.get("page") : 1, per_page: perPage }, data);
			setFilterShow(false);
			setIsInitLoading(false);
		};

		const onPagination = ({ page, per_page }) => {
			setPerPage(per_page);
			setSearchParams({ page: page });
			requestAuctions({ page: page, per_page: per_page }, formData);
		};

		const onResetFilter = () => {
			setDefaultItems();
			setFilterShow(false);
		};

		const onCloseFilter = () => {
			setFilterShow(false);
		};

		if (
			meStore.noPermission("auctions.view") &&
			meStore.noPermission("admin.auctions.view")
		) {
			// проверяем на наличие пермишена продавец
			if (meStore.hasPermission("auctions.view.seller")) return <Navigate to={SELLER_URL} />;
			return <Navigate to={NO_PERMISSION_URL} />;
		}

		const elViewSettings = () => {
			return (
				<div className="auctions-wrapper">
					<div className="d-flex align-items-center justify-content-between">
						<div className="title">
							<span>Аукционы</span>
						</div>
						{!isMobile && (
							<div className="d-flex">
								<LotChangeView
									nameView="auction_view"
									setView={setView}
								/>
								{meStore.hasPermission("admin.auctions.edit") &&
									!disableAddButton && (
										<Link
											to="/add-auction"
											className="btn btn-primary"
										>
											Добавить
										</Link>
									)}
							</div>
						)}
					</div>
				</div>
			);
		};

		return (
			<div className="total-wrapper" ref={$ref}>
				{isReadyFilter && (
					<Filter
						data={items}
						additional={itemsAdditional}
						typeFilter={"filter:trades"}
						typeFilterData={"filter:trades:data"}
						onSubmit={onSubmit}
						indent
						desktopShow={false}
						mobileShow={filterShow}
						onInitSelectField={onInitSelectField}
						onChangeSelectField={onChangeSelectField}
						onReset={onResetFilter}
						onClose={onCloseFilter}
					/>
				)}
				<div className="header-wrapper">
					{elViewSettings()}
					<FilterButton onClick={() => setFilterShow(true)} />
				</div>
				{auction?.is_active && (
					<AuctionItem
						isMobile={isMobile}
						fixedHeader={fixedAuctionHeader === auction.id}
						auction={auction}
						view={view}
						hasViewPermission={
							isMobile
								? VIEW_TILE
								: meStore.hasPermission("admin.auctions.view")
						}
					/>
				)}
				{auctions.data?.length > 0 ? (
					<>
						{auctions.data.map((item, index) => (
							<>
								<AuctionItem
									isMobile={isMobile}
									fixedHeader={fixedAuctionHeader === item.id}
									key={item.id}
									auction={item}
									view={view}
									hasViewPermission={
										isMobile
											? VIEW_TILE
											: meStore.hasPermission(
												"admin.auctions.view"
											)
									}
								/>
								<Separator opacity={index === auctions?.data?.length - 1} />
							</>
						))}
						<Pagination
							data={auctions}
							onChange={onPagination}
							perPageList={PER_PAGE_LIST}
							hideInfo={isMobile}
						/>
					</>
				) : (
					"Активных аукционов нет"
				)}
			</div>
		);
	}
);
