import merge from "lodash/merge";
import { makeAutoObservable, runInAction } from "mobx";

import {
	betAuctionRequest,
	getAuction,
	getAuctionBetsStepsRequest,
	getAuctionsList
} from "@api/auctions";
import { BET_IS_LEAD, BET_WAS_OUTBID } from "@utils/pusher-messages";

class AuctionsStore {
	constructor() {
		this.auctions = {};
		this.loading = true;

		this.lotBets = null;
		this.lotBetsId = null;

		this.betError = {};
		this.betSuccess = {};
		this.betCanceled = {};
		this.betNotification = {};
		this.betPlaced = {};
		this.lotDecreased = {};
		this.lotStarted = {};
		this.lotFinished = {};
		this.lotEndingWasChange = {};

		makeAutoObservable(this);
	}

	getAuctions = (params, endpointUrl, filters) => {
		runInAction(() => {
			this.loading = true;
		});
		return getAuctionsList(params, endpointUrl, filters).then((resp) => {
			runInAction(() => {
				this.loading = false;
			});

			if (resp.message) {
				console.error(resp);

				return "error";
			}

			runInAction(() => {
				this.auctions = resp;
			});

			return "success";
		});
	};

	getAuction = (id) => {
		getAuction(id).then((resp) => {
			this.auctions = { data: [resp] };
		});
	};

	betAuction = (auctionId, lotId, sum, isUpAuction) => {
		return betAuctionRequest(auctionId, lotId, sum).then((res) => {
			if (res.message) {
				runInAction(() => {
					this.betError[lotId] = res.message;
				});

				setTimeout(() => {
					runInAction(() => {
						this.betError[lotId] = null;
					});
				}, 3000);
			} else {
				runInAction(() => {
					if (isUpAuction) {
						this.betSuccess[lotId] = { lotId, key: res.key };
						this.betNotification[lotId] = null;
					} else {
						this.betSuccess[lotId] = { lotId };
					}
				});

				setTimeout(() => {
					runInAction(() => {
						this.betSuccess[lotId] = null;
					});
				}, 3000);
			}
		});
	};

	placeBet = (newData) => {
		const { bet_sum, auction_id, lot_id } = newData;

		this.updateLot(auction_id, lot_id, { lastBet: { sum: bet_sum } });

		runInAction(() => {
			this.betPlaced[lot_id] = newData;
		});

		if (this.lotBetsId === lot_id && this.lotBets) {
			this.getLastLotBet(auction_id, lot_id);
		}
	};

	cancelBet = (data) => {
		runInAction(() => {
			this.betNotification[data.lot_id] = null;
			this.betCanceled[data.lot_id] = data;
			this.betPlaced[data.lot_id] = data;
		});
	};

	decreaseLot = (newData) => {
		const { price, auction_id, lot_id } = newData;

		this.updateLot(auction_id, lot_id, { price });

		runInAction(() => {
			this.lotDecreased[auction_id] = Date.now();
		});

		setTimeout(() => {
			runInAction(() => {
				this.lotDecreased[auction_id] = null;
			});
		}, 200);
	};

	finishLot = (newData) => {
		const { auction_id, lot_id, finished_at, last_bet_sum } = newData;

		this.removeFinishLot(auction_id, lot_id, {
			is_active: false,
			finished_at,
			lastBet: { sum: last_bet_sum }
		});

		runInAction(() => {
			this.lotFinished[auction_id] = lot_id;

			setTimeout(() => {
				this.lotFinished[auction_id] = null;
			}, 200);
		});
	};

	startLot = (newData) => {
		const { auction_id, lot_id } = newData;

		this.updateLot(auction_id, lot_id, { is_active: true });

		runInAction(() => {
			this.lotStarted[auction_id] = lot_id;

			setTimeout(() => {
				this.lotStarted[auction_id] = null;
			}, 200);
		});
	};

	changeLotEnding = (newData) => {
		const { auction_id, lot_id, expires_at } = newData;

		this.updateLot(auction_id, lot_id, { expires_at });

		runInAction(() => {
			this.lotEndingWasChange[auction_id] = newData;

			setTimeout(() => {
				this.lotEndingWasChange[auction_id] = null;
			}, 200);
		});
	};

	setBetNotification = (data) => {
		const { auction_id, lot_id, type } = data;

		runInAction(() => {
			this.betCanceled[lot_id] = null;
			this.betNotification[lot_id] = data;

			if (type === BET_WAS_OUTBID) {
				this.updateLot(auction_id, lot_id, {
					lastBet: { outbid: true }
				});
			} else if (type === BET_IS_LEAD) {
				this.updateLot(auction_id, lot_id, {
					lastBet: { is_my: true }
				});
			}
		});
	};

	updateLot = (auctionId, lotId, data) => {
		const nextData = this.auctions.data.map((auc) => {
			return auc.id === auctionId
				? {
					...auc,
					lots: auc.lots.map((lot) =>
						lot.id === lotId ? { ...merge(lot, data) } : lot
					)
				}
				: auc;
		});

		runInAction(() => {
			this.auctions = { ...this.auctions, data: nextData };
		});
	};

	removeFinishLot = (auctionId, lotId, data) => {
		const nextData = this.auctions.data.filter(
			(auc) => auc.id !== auctionId
		);

		runInAction(() => {
			this.auctions = { ...this.auctions, data: nextData };
		});
	};

	refetchAuction = (auctionId) => {
		getAuction(auctionId).then((resp) => {
			const nextData = this.auctions.data.map((auc) => {
				return auc.id === resp.id ? { ...auc, ...resp } : auc;
			});

			runInAction((resp) => {
				this.auctions = { ...this.auctions, data: nextData };
			});
		});
	};

	getLotBets = (auctionId, lotId, params, isNextPage) => {
		getAuctionBetsStepsRequest(auctionId, lotId, params).then((resp) => {
			runInAction(() => {
				this.lotBetsId = lotId;

				if (isNextPage) {
					this.lotBets = {
						...resp,
						data: [...this.lotBets.data, ...resp.data]
					};
				} else {
					this.lotBets = resp;
				}
			});
		});
	};

	getLastLotBet = (auctionId, lotId) => {
		getAuctionBetsStepsRequest(auctionId, lotId, { per_page: 1 }).then(
			(resp) => {
				runInAction(() => {
					this.lotBets = {
						...this.lotBets,
						data: [resp.data[0], ...this.lotBets.data]
					};
				});
			}
		);
	};

	resetLotBets = () => {
		runInAction(() => {
			this.lotBetsId = null;
			this.lotBets = null;
		});
	};
}

export const auctionsStore = new AuctionsStore();
