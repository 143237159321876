import isEqual from "lodash/isEqual";
import moment from "moment/moment";
import { useEffect, useState } from "react";

import { postLotRequest, postMassLot } from "@api/auctions";
import { Input } from "@components/input/input";
import { getCurrencySymbol, isKZ } from "@utils/common";
import { formatToUTC } from "@utils/date";
import {
	formatToNumberWithoutSpaces,
	formatValueWithSpaces,
} from "@utils/format";

import { LotSalesTable } from "./lot-sales-table";

const getNewLot = (order, isDownAuction, expiresAt) => {
	const lot = {
		starting_price: "",
		sales: [],
		is_vat_included: true,
		expires_at: expiresAt || "",
		commission: "",
		minimal_commission: "",
		surcharge: "",
		order: order + 1,
		minimal_step: "",
	};

	if (isDownAuction) {
		lot.minimal_price = "";
	}

	return lot;
};

export const LotForm = (props) => {
	const { typeAuction, isEdit, isNewLot, isEditAdmin } = props;
	const isUpAuction = typeAuction === "parallel_increase";
	const isDownAuction = typeAuction === "sequential_decrease";
	const isAuctionBuyNow = typeAuction === "buy_now";

	const [dateType, setDateType] = useState("text");
	const [sales, setSales] = useState(isEdit ? props.lot?.sales : []);
	const [lot, setLot] = useState(
		isEdit
			? props.lot
			: getNewLot(props.order, isDownAuction, props.expiresAt)
	);
	const [lotError, setLotError] = useState("");
	const [formattedDate, setFormattedDate] = useState();

	useEffect(() => {
		if (isEdit && props.lot) {
			setSales(props.lot.sales);
			setLot(props.lot);
			setFormattedDate(
				moment(props.lot.expires_at).format("YYYY-MM-DDTHH:mm")
			);
		}
	}, [props.lot]);

	const requestSaveLot = (massLot) => {
		let data = {};

		if (isEdit) {
			Object.keys(lot).forEach((key) => {
				if (key === "id" || lot[key] !== props.lot[key]) {
					data[key] = lot[key];
				}
			});
		} else {
			data = { ...lot };
		}

		data.minimal_step &&
			(data.minimal_step = formatToNumberWithoutSpaces(
				data.minimal_step
			));
		data.starting_price &&
			(data.starting_price = formatToNumberWithoutSpaces(
				data.starting_price
			));
		data.minimal_commission &&
			(data.minimal_commission = formatToNumberWithoutSpaces(
				data.minimal_commission
			));
		data.surcharge &&
			(data.surcharge = formatToNumberWithoutSpaces(data.surcharge));

		if (data.expires_at) {
			data.expires_at = formatToUTC(data.expires_at);
		}

		const prevSales = props.lot?.sales.map((sale) => sale.id);
		data.sales = sales.map((sale) => sale.id);

		if (isEdit && isEqual(prevSales, data.sales)) {
			delete data.sales;
		}

		if (isUpAuction && data.blitz_price) {
			data.blitz_price = formatToNumberWithoutSpaces(data.blitz_price);
		}

		if (isDownAuction) {
			data.minimal_price &&
				(data.minimal_price = formatToNumberWithoutSpaces(
					data.minimal_price
				));
		} else {
			delete data.minimal_price;
		}

		if (isAuctionBuyNow) {
			data.minimal_step = 0;
		}

		const request = massLot
			? postMassLot(props.auctionId, data)
			: postLotRequest([data], props.auctionId);

		request.then((resp) => {
			if (resp.message) {
				setLotError(resp.errors || resp.message);

				setTimeout(() => setLotError(""), 5000);
			} else {
				props.onChange(resp);
			}
		});
	};

	const onChangeSales = (sales) => {
		setSales(sales);
	};

	const getDisabled = (field) => {
		return (
			isEdit &&
			props.editFields?.allowed_fields?.[0] !== "*" &&
			!props.editFields?.allowed_fields?.includes(field)
		);
	};

	return (
		<div className="info-lot-wrapper">
			<div className="info-wrapper__header">
				{isEdit ? "Изменение" : "Добавление"} лота
			</div>
			<div className="info-lot-wrapper__body">
				<LotSalesTable
					sales={sales}
					onChangeSales={onChangeSales}
					withCreateSale={true}
					isEdit={isEditAdmin}
					isNewLot={isNewLot}
					isUpAuction={isUpAuction}
					singleSelect={isUpAuction && isEditAdmin}
				/>
				{sales?.length > 0 && (
					<div className="mt-4">
						<div className="lots-wrapper">
							{isDownAuction && (
								<div className="info-lot-wrapper__row">
									<div className="name">№</div>
									<div className="d-flex align-items-center">
										<Input
											type="number"
											name="order"
											autoComplete="off"
											onChange={(e) =>
												setLot((prevLot) => ({
													...prevLot,
													order: e.target.value,
												}))
											}
											value={lot?.order}
											className="input-lot-form"
											disabled={getDisabled("order")}
										/>
									</div>
								</div>
							)}
							<div className="info-lot-wrapper__row">
								<div className="name">
									{isAuctionBuyNow
										? "Стоимость"
										: "Стартовая стоимость"}
									, {getCurrencySymbol()}
								</div>
								<div className="d-flex align-items-center position-relative">
									<Input
										name="starting_price"
										autoComplete="off"
										onChange={(e) =>
											setLot((prevLot) => ({
												...prevLot,
												starting_price:
													formatValueWithSpaces(
														e.target.value
													),
											}))
										}
										value={lot?.starting_price}
										className="input-lot-form"
										placeholder={
											isKZ ? "В тенге" : "В рублях"
										}
										disabled={getDisabled("starting_price")}
									/>
								</div>
							</div>
							{isDownAuction && (
								<div className="info-lot-wrapper__row">
									<div className="name">
										Минимальная стоимость,{" "}
										{getCurrencySymbol()}
									</div>
									<div className="d-flex align-items-center position-relative">
										<Input
											name="minimal_price"
											autoComplete="off"
											onChange={(e) =>
												setLot((prevLot) => ({
													...prevLot,
													minimal_price:
														formatValueWithSpaces(
															e.target.value
														),
												}))
											}
											value={lot?.minimal_price}
											className="input-lot-form"
											placeholder={
												isKZ ? "В тенге" : "В рублях"
											}
											disabled={getDisabled(
												"minimal_price"
											)}
										/>
									</div>
								</div>
							)}
							{!isAuctionBuyNow && (
								<div className="info-lot-wrapper__row">
									<div className="name">
										Шаг торгов / понижения,{" "}
										{getCurrencySymbol()}
									</div>
									<div className="d-flex align-items-center position-relative">
										<Input
											name="minimal_step"
											autoComplete="off"
											onChange={(e) =>
												setLot((prevLot) => ({
													...prevLot,
													minimal_step:
														formatValueWithSpaces(
															e.target.value
														),
												}))
											}
											value={lot?.minimal_step}
											className="input-lot-form"
											placeholder={
												isKZ ? "В тенге" : "В рублях"
											}
											disabled={getDisabled(
												"minimal_step"
											)}
										/>
									</div>
								</div>
							)}
							{isUpAuction && (
								<div className="info-lot-wrapper__row">
									<div className="name">
										Можно купить по блиц-цене?
									</div>
									<div className="d-flex align-items-center gap-16px">
										<div className="checkbox-wrapper h-44px">
											<input
												type="checkbox"
												name="is_blitz_price_enabled"
												className={
													lot?.is_blitz_price_enabled
														? "checked"
														: ""
												}
												onChange={(e) =>
													setLot((prevLot) => ({
														...prevLot,
														is_blitz_price_enabled:
															e.target.checked,
													}))
												}
												checked={
													lot?.is_blitz_price_enabled
												}
												disabled={getDisabled(
													"is_blitz_price_enabled"
												)}
											/>
											Да
										</div>
										{lot?.is_blitz_price_enabled && (
											<Input
												name="blitz_price"
												onChange={(e) => {
													setLot((prevLot) => ({
														...prevLot,
														blitz_price:
															formatValueWithSpaces(
																e.target.value
															),
													}));
												}}
												value={lot?.blitz_price}
												className="info-lot-wrapper__input-blitz"
												placeholder={`Блиц-цена, ${
													isKZ ? "тенге" : "руб"
												}`}
												disabled={getDisabled(
													"blitz_price"
												)}
											/>
										)}
									</div>
								</div>
							)}
							{(isUpAuction || isAuctionBuyNow) && (
								<div className="info-lot-wrapper__row">
									<div className="name">Дата завершения</div>
									<div className="d-flex align-items-center">
										<Input
											type={"datetime-local"}
											name="expires_at"
											onChange={(e) => {
												setLot((prevLot) => ({
													...prevLot,
													expires_at: e.target.value,
												}));
												setFormattedDate(
													moment(
														e.target.value
													).format("YYYY-MM-DDTHH:mm")
												);
											}}
											value={formattedDate}
											className="input-lot-form"
											placeholder="Дата завершения"
											disabled={getDisabled("expires_at")}
										/>
									</div>
								</div>
							)}
							<div className="info-lot-wrapper__row">
								<div className="name">
									НДС включён в стоимость?
								</div>
								<div className="d-flex align-items-center position-relative">
									<div className="checkbox-wrapper">
										<input
											type="checkbox"
											name="is_vat_included"
											className={
												lot?.is_vat_included
													? "checked"
													: ""
											}
											onChange={(e) =>
												setLot((prevLot) => ({
													...prevLot,
													is_vat_included:
														e.target.checked,
												}))
											}
											checked={lot?.is_vat_included}
											disabled={getDisabled(
												"is_vat_included"
											)}
										/>
										{lot?.is_vat_included ? "Да" : "Нет"}
									</div>
								</div>
							</div>
							<div className="info-lot-wrapper__row">
								<div className="name">Комиссия</div>
								<div className="d-flex align-items-center position-relative gap-15px">
									<div className="position-relative">
										<Input
											name="commission"
											autoComplete="off"
											onChange={(e) =>
												setLot((prevLot) => ({
													...prevLot,
													commission: e.target.value,
												}))
											}
											value={lot?.commission}
											className="auction-lots__input-commission"
											disabled={getDisabled("commission")}
											placeholder="0"
										/>
										<span className="auction-lots__placeholder-symbol">
											%
										</span>
									</div>
									<div>
										<div className="input-name">
											Минимум
										</div>
										<div className="position-relative">
											<Input
												name="minimal_commission"
												autoComplete="off"
												onChange={(e) =>
													setLot((prevLot) => ({
														...prevLot,
														minimal_commission:
															formatValueWithSpaces(
																e.target.value
															),
													}))
												}
												value={lot?.minimal_commission}
												className="auction-lots__input-minimal-commission"
												disabled={getDisabled(
													"minimal_commission"
												)}
												placeholder="0"
											/>
											<span className="auction-lots__placeholder-symbol">
												{getCurrencySymbol()}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="info-lot-wrapper__row">
								<div className="name">
									Штраф, {getCurrencySymbol()}
								</div>
								<div className="d-flex align-items-center position-relative">
									<Input
										name="surcharge"
										autoComplete="off"
										onChange={(e) =>
											setLot((prevLot) => ({
												...prevLot,
												surcharge:
													formatValueWithSpaces(
														e.target.value
													),
											}))
										}
										value={lot?.surcharge}
										className="input-lot-form"
										placeholder={isKZ ? "" : "30 000"}
										disabled={getDisabled("surcharge")}
									/>
								</div>
							</div>
						</div>
						{Boolean(lotError) && (
							<div className="mt-2 mb-2 alert alert-danger">
								{JSON.stringify(lotError, null, " ")}
							</div>
						)}
						<div className="d-flex gap-10px lot-form__buttons">
							<button
								onClick={() => requestSaveLot()}
								className="btn btn-primary mt-3"
								type="button"
							>
								{isEdit
									? "Сохранить лот"
									: sales?.length > 1
									? "Создать один лот"
									: "Создать лот"}
							</button>
							{!isEdit && isUpAuction && sales?.length > 1 && (
								<button
									onClick={() => requestSaveLot(true)}
									className="btn btn-ghost mt-3"
									type="button"
								>
									Создать лот на каждый автомобиль
								</button>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
