import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { Steps } from "@components/steps/steps";
import { carSaleStore } from "@mobx/car-sale";
import { meStore } from "@mobx/me";
import { NO_PERMISSION_URL } from "@utils/common";

import { Comment } from "./car-form-components/comment";
import { Completeness } from "./car-form-components/completeness";
import { Documents } from "./car-form-components/documents";
import { Equipment } from "./car-form-components/equipment";
import { Final } from "./car-form-components/final";
import { GeneralInformation } from "./car-form-components/general-information";
import { Photos } from "./car-form-components/photos";

import "./car-form.scss";

const steps = [
	{ description: "Общая информация" },
	{ description: "Комплектность" },
	{ description: "Комплектация" },
	{ description: "Фотографии" },
	{ description: "Документы" },
	{ description: "Комментарий" },
	{ description: "Завершение" }
];

const components = [
	GeneralInformation,
	Completeness,
	Equipment,
	Photos,
	Documents,
	Comment,
	Final
];

export const CarForm = observer((props) => {
	const { id: editId } = useParams();

	const [activeStep, setActiveStep] = useState(0);
	const [clickedStep, setClickedStep] = useState();
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);

	const onStepChange = useCallback(() => {
		setActiveStep((prev) => prev + 1);
	}, []);

	const onStepClick = ({ index }) => {
		if (props.edit) {
			setClickedStep(index);
			setShowConfirmPopup(true);
		}
	};

	const onConfirmChangeStep = () => {
		setActiveStep(clickedStep);
		setShowConfirmPopup(false);
	};

	useEffect(() => {
		if (props.edit) {
			carSaleStore.fetchCar(editId);
		}
		return () => {
			carSaleStore.clearData();
		};
	}, []);

	if (
		meStore.noPermission("admin.sales.edit") &&
		meStore.noPermission("sales.edit")
	) {
		return <Navigate to={NO_PERMISSION_URL} />;
	}

	if (meStore.data === null) {
		return null;
	}

	const Component = components[activeStep];

	return (
		<div className="create-car-sale-wrapper">
			<div className="title">
				<span>
					{props.edit ? "Изменение" : "Добавление"} автомобиля
				</span>
			</div>
			<hr />
			<Steps steps={steps} step={activeStep} onStepClick={onStepClick} />
			<hr />
			<Component
				onStepChange={onStepChange}
				edit={props.edit}
				carData={carSaleStore.data}
				showConfirmPopup={showConfirmPopup}
				onConfirmChangeStep={onConfirmChangeStep}
			/>
		</div>
	);
});
