import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { getRequisitesTemplates, getUserById } from "@api/users";
import { DropdownButton } from "@components/dropdown/dropdown-button";
import { InfoList } from "@components/info-list/info-list";
import { Loader } from "@components/loader/loader";
import { RadioButton } from "@components/radio-button/radio-button";
import { citiesStore } from "@mobx/cities";
import { meStore } from "@mobx/me";
import RoleViewUser from "@pages/role/RoleViewUser/RoleViewUser";
import { getCompanies } from "@pages/user/utils";
import { phoneNormalize } from "@utils/common";
import { formatPhoneMask } from "@utils/format";
import "./user-profile.scss";

export const UserProfile = observer(() => {
	const navigate = useNavigate();
	const [fields, setFields] = useState([]);
	const [user, setUser] = useState();
	const [fullName, setFullName] = useState();
	const [roles, setRoles] = useState([]);
	const [fieldsMe, setFieldsMe] = useState();

	const [company, setCompany] = useState();
	const [city, setCity] = useState("-");
	const [fieldsCompany, setFieldsCompany] = useState([]);

	useEffect(() => {
		getUserById(meStore.data?.user?.id).then((data) => {
			setUser(data);
			setFullName((prevState) => {
				let name = data.last_name + " " + data.first_name;
				if (data.middle_name) return name + " " + data.middle_name;
				return name;
			});
		});
		getRequisitesTemplates().then((resp) => {
			if (resp?.personal?.fields) {
				setFields(Object.values(resp.personal.fields));
			}
		});

		getRequisitesTemplates().then((resp) => {
			if (resp?.legal?.fields) {
				setFieldsCompany(Object.values(resp.legal.fields));
			}
		});
	}, []);

	useEffect(() => {
		setRoles(meStore.data?.impersonate_as?.entity?.roles);
		if (meStore.data?.impersonate_as?.type === "company") {
			(async () => {
				if (!citiesStore.cities.length) await citiesStore.fetch();

				if (meStore.data?.impersonate_as?.entity?.city_id)
					setCity(
						citiesStore.cities.find(
							(x) =>
								x.id ===
								meStore.data?.impersonate_as?.entity?.city_id
						).name
					);
				setCompany(meStore.data?.impersonate_as?.entity);
			})();
		} else {
			setCompany(null);
		}
	}, [meStore.data]);

	if (!user) {
		return <Loader />;
	}

	const renderDynamicField = (item) => {
		return (
			<div key={item.key} className="user-info__field-wrapper">
				<div className="name">{item.name}</div>
				<div className="value">
					{item.key === "need_deposit"
						? user?.requisite?.[item.key]
							? "Да"
							: "Нет"
						: user?.requisite?.[item.key] || "-"}
				</div>
			</div>
		);
	};

	const renderDynamicFieldCompany = (item) => {
		return (
			<div
				key={item.key}
				className="company-info__field-wrapper d-grid grid-columns-30-70"
			>
				<div className="name">{item.name}</div>
				<div className="value">
					{company?.requisite?.[item.key] || "-"}
				</div>
			</div>
		);
	};

	const elActions = () => {
		if (meStore.hasPermission("admin.users.edit")) {
			return (
				<div className="d-flex actions">
					<div className="d-flex align-items-center action">
						<DropdownButton
							btnText="Действия"
							options={[
								{
									label: "Редактировать",
									value: "edit",
									onClick: () =>
										navigate(`/users/${user.id}/edit`)
								},
								{
									label: "Изменить пароль",
									value: "changePassword",
									onClick: () =>
										navigate(
											`/users/${user.id}/change-password`
										)
								}
							]}
						/>
					</div>
				</div>
			);
		}

		return (
			<Link to="/profile/change-password" className="btn btn-primary">
				Изменить пароль
			</Link>
		);
	};

	return (
		<div className="user-profile">
			<InfoList
				title={`${user.last_name} ${user.first_name}`}
				actions={elActions()}
				fields={[
					{ label: "ФИО", value: fullName, isShowField: true },
					{
						label: "Телефон",
						value: formatPhoneMask(user.phone),
						isShowField: true
					},
					{
						label: "Город",
						value: user.city ? user.city.name : "-",
						isShowField: true
					},
					{ label: "Почта", value: user.email, isShowField: true },
					{
						label: "Ответственный менеджер",
						value: user.responsible?.full_name ? (<>
							<div>
								{user.responsible?.full_name}
							</div>
							{user?.responsible?.email && <div><a
								href={`mailto:${user?.responsible?.email}`}
							>
								{user?.responsible?.email}
							</a></div>}
							{user?.responsible?.phone && <div>
								<a
									href={`tel:+${phoneNormalize(
										user.responsible?.phone
									)}`}
								>
									+
									{phoneNormalize(
										user.responsible?.phone
									)}
								</a>
							</div>}
						</>) : "-",
						isShowField: !company
					},
					{
						label: "Заявленная компания",
						value: user.declared_company_name || "-",
						isShowField: true
					},
					{
						label: "Правовая форма",
						value: user.legal_form?.name || "-",
						isShowField: true
					},
					{
						label: "Компании",
						value: getCompanies(user),
						isShowField: true
					},
					{
						custom: (
							<div className="info-list__item align-items-center">
								<div className="name">Депозит</div>
								<div className="d-flex gap-10px align-items-center h-40px">
									<RadioButton
										id="input-yes"
										label="Да"
										name="requisite.need_deposit"
										value="true"
										disabled={true}
										defaultChecked={
											user.requisite?.need_deposit ===
											true
										}
									/>
									<RadioButton
										id="input-no"
										label="Нет"
										value="false"
										name="requisite.need_deposit"
										disabled={true}
										defaultChecked={
											user.requisite?.need_deposit ===
											false
										}
									/>
								</div>
							</div>
						),
						isShowField: true
					}
				].filter((x) => x.isShowField)}
			/>

			{company ? (
				<div className="company-wrapper company-wrapper-profile">
					<div className="title">Компания</div>
					<div className="company-info mt-3">
						<div className="d-grid grid-columns-30-70 company-info__field-wrapper">
							<div className="name">Название организации</div>
							<div className="value">{company.title}</div>
						</div>
						<div className="d-grid grid-columns-30-70 company-info__field-wrapper">
							<div className="name">Тип компании</div>
							<div className="value">
								{company.type_id ? company.type?.name : "-"}
							</div>
						</div>
						<div className="d-grid grid-columns-30-70 company-info__field-wrapper">
							<div className="name">Ответственный менеджер</div>
							<div className="value value_responsible">
								{company.responsible?.full_name || "-"}
								{company.responsible?.email && (
									<a
										href={`mailto:${company.responsible?.email}`}
									>
										{company.responsible?.email}
									</a>
								)}
								{company.responsible?.phone && (
									<a
										href={`tel:+${phoneNormalize(
											company.responsible?.phone
										)}`}
									>
										+
										{phoneNormalize(
											company.responsible?.phone
										)}
									</a>
								)}
							</div>
						</div>
						<div className="d-grid grid-columns-30-70 company-info__field-wrapper">
							<div className="name">Логотип</div>
							<div className="value">
								{company?.logo?.url ? (
									<a
										href={company.logo.url}
										target="_blank"
										rel="noreferrer"
									>
										<img
											src={company.logo.url}
											alt={company.logo.original_name}
											className="image"
										/>
									</a>
								) : (
									"-"
								)}
							</div>
						</div>
					</div>

					{fieldsCompany.length > 0 && (
						<div className="mt-4">
							<div className="title">Реквизиты</div>
							<div className="company-info mt-3">
								<div className="d-grid grid-columns-30-70 company-info__field-wrapper">
									<div className="name">Город</div>
									<div className="value">{city}</div>
								</div>
								{fieldsCompany.map((item) =>
									item.key === "agent_contract" ? (
										<div
											key={item.key}
											className="d-grid grid-columns-30-70 company-info__field-wrapper"
										>
											<div className="name">Документ</div>
											<div className="value">
												{company?.requisite
													?.agent_contract?.url ? (
													<a
														href={
															company.requisite
																.agent_contract
																.url
														}
														download={
															company.requisite
																.agent_contract
																.original_name
														}
													>
														{
															company.requisite
																.agent_contract
																.original_name
														}
													</a>
												) : (
													"-"
												)}
											</div>
										</div>
									) : (
										renderDynamicFieldCompany(item)
									)
								)}
							</div>
						</div>
					)}
				</div>
			) : (
				fields.length > 0 && (
					<div className="mt-4">
						<div className="title">Реквизиты</div>
						<div className="user-info mt-3">
							{fields.map((item) =>
								item.key === "agent_contract" ? (
									<div
										key={item.key}
										className="user-info__field-wrapper"
									>
										<div className="name">Договор</div>
										<div className="value">
											{user?.requisite?.agent_contract
												?.url ? (
												<a
													href={
														user.requisite
															.agent_contract.url
													}
													download={
														user.requisite
															.agent_contract
															.original_name
													}
												>
													{
														user.requisite
															.agent_contract
															.original_name
													}
												</a>
											) : (
												"-"
											)}
										</div>
									</div>
								) : (
									renderDynamicField(item)
								)
							)}
						</div>
					</div>
				)
			)}

			<RoleViewUser items={roles} />
		</div>
	);
});
