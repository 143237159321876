import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { postCreateReport } from "@api/reports";

import "./car-form-components.scss";

export const Final = ({
	carData,
	edit,
	showConfirmPopup,
	onConfirmChangeStep,
}) => {
	const navigate = useNavigate();
	const carId = carData?.id;

	useEffect(() => {
		if (showConfirmPopup) {
			onConfirmChangeStep?.();
		}
	}, [showConfirmPopup]);

	const goToCar = () => {
		navigate(`/car/${carId}`);
	};

	const handleCreateReportAndRedirect = async () => {
		postCreateReport(carId).then((resp) => {
			if (resp.id) {
				navigate(`/car/${carId}/report/${resp.id}`);
			}
		});
	};

	return (
		<div>
			<div className="title mb-5">
				Автомобиль успешно {edit ? "изменен" : "добавлен"}
			</div>
			<div className="mob-padding text-margin">
				Выберите дальнейшие действия с автомобилем
			</div>
			<div className="d-flex align-items-center justify-content-between gap-5 mob-padding">
				{carData?.metadata?.actions?.["statusReports.create"] && (
					<button
						type="button"
						onClick={handleCreateReportAndRedirect}
						className="btn btn-primary w-100 button"
					>
						Добавить отчет
					</button>
				)}
				<button
					type="button"
					className="btn w-100 button btn-secondary"
					onClick={goToCar}
				>
					Просмотреть автомобиль
				</button>
			</div>
		</div>
	);
};
