import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useParams } from "react-router-dom";

import {
	createReportCommentRequest,
	getReportsCommentsRequest,
} from "@api/cars";

import "./comments-accordion.scss";

export const CommentsAccordion = ({ isMobile, eventKey }) => {
	const [comments, setComments] = useState();
	const [comment, setComment] = useState("");

	const { reportId } = useParams();
	const decoratedOnClick = useAccordionButton(eventKey);

	useEffect(() => {
		if (reportId) {
			getReportsCommentsRequest(reportId).then(setComments);
		}
	}, []);

	const addComment = async () => {
		if (comment) {
			await createReportCommentRequest(reportId, comment);
			getReportsCommentsRequest(reportId).then((resp) => {
				setComments(resp);
				setComment("");
			});
		}
	};

	const onClose = (e) => {
		decoratedOnClick(e);
		addComment();
	};
	return (
		<div className="comments-wrapper">
			<hr />

			<div
				className={clsx(
					"d-flex align-items-center justify-content-between",
					{ "mob-comments-flex": isMobile }
				)}
			>
				<div className="title">
					Дополнительная информация для аукциона
				</div>
				<button className="save-btn" type="button" onClick={onClose}>
					Сохранить
				</button>
			</div>

			<textarea
				onChange={(e) => setComment(e.target.value)}
				value={comment}
				className="comment-field mt-3"
			/>
			<hr />

			<div className="d-flex align-items-center justify-content-between">
				{comments?.data?.length > 0 && (
					<div className="comments-length">
						{comments.data.length} комментариев
					</div>
				)}
				<button
					onClick={addComment}
					className={clsx("btn btn-primary", { "w-50": isMobile })}
					type="button"
				>
					Добавить
				</button>
			</div>

			<div className="mt-4">
				{comments?.data?.length > 0
					? comments.data.map((commentEl) => (
							<div
								className={clsx(
									"d-flex justify-content-between w-100 mt-2",
									{
										"mob-comments-flex align-items-start":
											isMobile,
										"align-items-center": !isMobile,
									}
								)}
								key={commentEl.id}
							>
								<div
									className={clsx(
										"d-flex align-items-center",
										{ "w-100": isMobile, "w-50": !isMobile }
									)}
								>
									<img
										src="/img/icons/user-icon.svg"
										alt=""
									/>
									<div className="company-name">
										<div className="name">
											{commentEl?.author?.full_name}
										</div>
										<div className="company">
											{commentEl?.author?.admin_comment}
										</div>
									</div>
								</div>
								<div
									className={clsx(
										"comment align-items-center text-justify p-2",
										{ "w-100": isMobile, "w-50": !isMobile }
									)}
								>
									{commentEl.body}
								</div>
								<hr />
							</div>
					  ))
					: "Нет комментариев"}
			</div>
		</div>
	);
};
