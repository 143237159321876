import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { dictionariesStore } from "@mobx/dictionaries";
import { managersStore } from "@mobx/managers";
import { meStore } from "@mobx/me";
import { NO_PERMISSION_URL } from "@utils/common";
import { DocumentField } from "@pages/car-form/car-form-components/components/document-field";
import { Input } from "@components/input/input";
import { getRequisitesTemplates } from "@api/users";
import { Loader } from "@components/loader/loader";
import { createCompanyRequest, getSingleCompanyRequest, postEditCompany } from "@api/companies";
// import { Select } from '@components/select/select';
import Select from "@components/Forms/Select/Select";

import "./company-form.scss";
import { citiesStore } from "@mobx/cities";

export const CompanyForm = observer(({ isEdit }) => {

	const { id } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState();
	const [company, setCompany] = useState();
	const [fields, setFields] = useState([]);
	const [roles, setRoles] = useState([]);
	const [form, setForm] = useState({
		title: "",
		type_id: "",
		responsible_id: "",
		requisite: {}
	});

	const [defaultTypeCompany, setDefaultTypeCompany] = useState("");
	const [defaultResponsible, setDefaultResponsible] = useState("");
	const [defaultCity, setDefaultCity] = useState("");

	useEffect(() => {
		dictionariesStore.request("company_types", { filters: { entity: "company" } });

		if (!managersStore.managers.length) {
			managersStore.fetch();
		}

		if (!citiesStore.cities.length) citiesStore.fetch();

		getRequisitesTemplates().then((resp) => {
			if (resp?.legal?.fields) {
				const fields = [];
				const requisite = {};

				Object.values(resp.legal.fields).forEach((field) => {
					fields.push(field);
					requisite[field.key] = "";
				});

				setForm((prev) => ({ ...prev, requisite }));
				setFields(fields);

				if (isEdit) {
					getSingleCompanyRequest(id).then(data => {
						setCompany(data);
						if (data.responsible_id) setDefaultResponsible({
							label: data.responsible.full_name,
							value: data.responsible.id
						});
						if (data.city_id) {
							let city = citiesStore.cities.find(x => x.id === data?.city_id);
							setDefaultCity({ label: city.name, value: city.id });
						}
						if (data.type_id) {
							setDefaultTypeCompany({ label: data.type.name, value: data.type.id });
						}
					});
				} else {
					setLoading(false);
				}
			}
		});
	}, []);

	useEffect(() => {
		if (company && isEdit) {
			const values = {
				title: company.title,
				type_id: company.type_id || "",
				responsible_id: company.responsible_id || "",
				requisite: {}
			};

			Object.keys(form?.requisite).forEach((key) => {
				if (key !== "agent_contract") {
					values.requisite[key] = company.requisite?.[key] || "";
				}
			});

			setForm(values);
			setLoading(false);
		}
	}, [company]);

	if (meStore.noPermission("admin.companies.edit")) {
		return <Navigate to={NO_PERMISSION_URL} />;
	}

	if (meStore.data === null || loading) {
		return <Loader />;
	}
	;

	const handleInputChange = (e) => {
		const { name } = e.target;
		if (name.includes("requisite.")) {
			const newName = name.replace("requisite.", "");

			setForm({
				...form,
				requisite: {
					...form.requisite,
					[newName]: e.target.value
				}
			});
		} else {
			setForm({
				...form,
				[name]: e.target.value
			});
		}
	};

	const handleSaveRequest = () => {
		const data = { ...form };

		if (isEdit || roles?.length > 0) {
			data.roles = roles;
		}

		const request = isEdit ? postEditCompany(id, data) : createCompanyRequest(data);

		request.then((resp) => {
			if (resp.message) {
				setErrors(resp.errors);

				setTimeout(() => {
					setErrors(null);
				}, 5000);
			} else {
				navigate(`/company/${resp.id}`);
			}
		});
	};

	const renderDynamicInput = (item) => {
		return (
			<div key={item.key} className="d-flex align-items-center mt-2">
				<div className="name w-30">{item.name} ---</div>
				<Input
					type={item.type === "string" ? "text" : item.type}
					name={`requisite.${item.key}`}
					onChange={handleInputChange}
					value={form.requisite?.[item.key]}
					placeholder={item.name}
				/>
			</div>
		);
	};

	return (
		<div className="create-companies-wrapper">
			<div className="head-text">{isEdit ? "Редактирование компании" : "Добавление компании"}</div>
			<div className="info-wrapper mt-4">
				<div className="d-flex align-items-center mt-2">
					<div className="name w-30">Название компании</div>
					<Input
						name="title"
						onChange={handleInputChange}
						value={form.title}
						placeholder="Название компании *"
					/>
				</div>
				<div className="d-flex align-items-center mt-2">
					<div className="name w-30">Тип компании</div>
					<Select
						className="react-select"
						options={dictionariesStore.dictionaries.company_types?.map((type) => ({
							value: type.value,
							label: type.label
						}))}
						placeholder={"Тип компании"}
						defaultValue={defaultTypeCompany}
						onChange={(e) =>
							handleInputChange({
								target: {
									name: "type_id",
									value: e?.value
								}
							})
						}
					/>
				</div>
				<div className="d-flex align-items-center mt-2">
					<div className="name w-30">Ответственный менеджер</div>
					<Select
						className="react-select"
						options={managersStore.managers?.map((user) => ({ value: user.id, label: user.full_name }))}
						placeholder={"Ответственный менеджер"}
						defaultValue={defaultResponsible}
						onChange={(e) =>
							handleInputChange({
								target: {
									name: "responsible_id",
									value: e?.value ? e?.value : "null"
								}
							})
						}
						сlearable={true}
					/>
				</div>
				<div className="d-flex align-items-center mt-2">
					<div className="name w-30">Город</div>
					<Select
						className="react-select"
						options={citiesStore.cities.map(x => ({ value: x.id, label: x.name }))}
						placeholder={"Город"}
						defaultValue={defaultCity}
						onChange={(e) =>
							handleInputChange({
								target: {
									name: "city_id",
									value: e?.value
								}
							})
						}
					/>
				</div>
				{fields.length > 0 && fields.map((item) => {
					return (item.key === "agent_contract" && item.type === "file") ? (
						<div key={item.key} className="mt-2 document-field__wrapper">
							<DocumentField
								label="Договор"
								name="requisite.agent_contract"
								defaultValue={company?.requisite?.agent_contract}
								onSave={(file, name) => handleInputChange({ target: { name, value: file } })}
								onDelete={() => {
									handleInputChange({ target: { name: "requisite.agent_contract", value: "" } });
								}}
							/>
						</div>
					) : renderDynamicInput(item);
				})}
				<div className="mt-2 document-field__wrapper">
					<DocumentField
						label="Логотип"
						name="logo"
						defaultValue={company?.logo}
						onSave={(file, name) => handleInputChange({ target: { name, value: file } })}
						onDelete={() => handleInputChange({ target: { name: "logo", value: "" } })}
					/>
				</div>
			</div>
			{errors && Object.values(errors).map((error) => (
				<Alert key={error} variant="danger">
					{error}
				</Alert>
			))}
			<button
				onClick={handleSaveRequest}
				className="btn btn-primary mt-4 w-100"
				type="submit"
			>
				Сохранить организацию
			</button>
		</div>
	);
});
