import { DELETE, GET, POST, Send } from "./request";

const defaultParams = {
	"expand[0]": "type",
	"expand[1]": "requisite",
	"expand[2]": "responsible",
	"expand[3]": "roles"
};

const getNewData = (data) => {
	const newData = new FormData();

	Object.keys(data).forEach((key) => {
		if (key === "logo" && data[key] === "") {
			newData.append("logo", "");
		}

		if (data[key]) {
			if (key === "requisite") {
				const requisite = data[key];

				Object.keys(requisite).forEach((reqKey) => {
					if (requisite[reqKey]) {
						newData.append(`requisite[${reqKey}]`, requisite[reqKey]);
					}
					if (reqKey === "agent_contract" && requisite[reqKey] === "") {
						newData.append(`requisite[${reqKey}]`, "");
					}
				});
			} else if (key === "roles") {
				if (data.roles.length) {
					data.roles.forEach((item) => {
						newData.append("roles[]", item);
					});
				} else {
					newData.append("roles[]", []);
				}
			} else {
				newData.append(key, data[key]);
			}
		}
	});

	return newData;
};

export const getFilteredCompanies = ({ title, company_name, inn }, params) => {
	const newParams = { ...defaultParams, ...(params || {}) };

	if (title) newParams["filters[title]"] = title;
	if (company_name) newParams["filters[requisite][company_name]"] = company_name;
	if (inn) newParams["filters[requisite][inn]"] = inn;

	return GET("companies", newParams);
};

export const getCompaniesRequest = (params) => {
	const newParams = {
		...defaultParams,
		...params
	};

	return GET("companies", newParams);
};

export const createCompanyRequest = (data) => {
	const newData = getNewData(data);

	return Send("companies", "POST", newData, true, undefined, true, { "content-type": "multipart/form-data" });
};

export const getSingleCompanyRequest = (id) => {
	return Send(`companies/${id}?expand[0]=type&expand[1]=requisite&expand[2]=responsible&expand[3]=logo&expand[4]=requisite.agentContract&expand[5]=responsible&expand[6]=roles`, "GET");
};

export const postEditCompany = (id, data) => {
	const newData = getNewData(data);

	return Send(`companies/${id}`, "POST", newData, true, { _method: "PUT" }, true, { "content-type": "multipart/form-data" });
};

export const getSingleCompanyDepartmentsRequest = (id) => {
	return GET(`companies/${id}/departments`);
};

export const getDepartmentsUsersRequest = (department_id) => {
	return GET(`departments/${department_id}/users`);
};

export const getCompaniesUsersRequest = (company_id) => {
	return GET(`companies/${company_id}/users`);
};

export const addUsersToCompanyRequest = (id, data) => {
	return POST(`companies/${id}/users`, { user_ids: data.user_ids });
};

export const createDepartmentRequest = (id, data) => {
	const newData = getNewData(data);

	return Send(`companies/${id}/departments`, "POST", newData, true, undefined, true, { "content-type": "multipart/form-data" });
};

export const editDepartmentRequest = (id, data) => {
	const newData = getNewData(data);

	return Send(`departments/${id}`, "POST", newData, true, { _method: "PUT" }, true, { "content-type": "multipart/form-data" });
};

export const addUsersToDepartmentRequest = ({ id, user_ids }) => {
	return POST(`departments/${id}/users`, { user_ids });
};

export const deteleUsersFromDepartmentRequest = ({ id, user_ids }) => {
	return DELETE(`departments/${id}/users`, { user_ids });
};

export const deleteUsersFromCompanyRequest = (id, data) => {
	return DELETE(`companies/${id}/users`, data);
};

export const getCurrentDepartmentRequest = (department_id) => {
	return GET(`departments/${department_id}?expand[0]=requisite&expand[1]=responsible&expand[2]=requisite.agentContract&expand[3]=roles`);
};
