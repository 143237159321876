import { GET, POST, Send, DELETE } from "./request";

const defaultParams = {
	"expand[0]": "status",
	"expand[1]": "roles",
	"expand[2]": "city",
	"expand[3]": "legalForm"
};

export const getUsersList = (params: any) => {
	const newParams = {
		...defaultParams,
		...params
	};

	return GET("users", newParams);
};

const getUserData = (data: any) => {
	const newData = new FormData();

	Object.keys(data).forEach((key) => {
		if (data[key]) {
			if (key === "requisite") {
				const requisite = data[key];

				Object.keys(requisite).forEach((reqKey) => {
					if (requisite[reqKey] || reqKey === "need_deposit") {
						newData.append(`requisite[${reqKey}]`, requisite[reqKey]);
					}
					if (reqKey === "agent_contract" && requisite[reqKey] === null) {
						newData.append(`requisite[${reqKey}]`, "");
					}
				});
			} else if (key === "roles") {
				if (data.roles.length) {
					data.roles.forEach((item: any) => {
						newData.append("roles[]", item);
					});
				} else {
					// @ts-ignore
					newData.append("roles[]", []);
				}
			} else {
				newData.append(key, data[key]);
			}
		}
	});

	return newData;
};

export const userCreate = (data: any) => {
	const newData = getUserData(data);

	return Send("users", "POST", newData, true, undefined, true, { "content-type": "multipart/form-data" });
};

export const userUpdateRequest = (id: string | number, data: any) => {
	const newData = getUserData(data);

	return Send(`users/${id}`, "POST", newData, true, { _method: "PUT" }, true, { "content-type": "multipart/form-data" });
};

export const filteredUsers = (filters: any, params: any) => {
	const newParams = { ...filters, ...defaultParams, ...(params || {}) };

	return GET("users", newParams);
};

export const getUserById = (id: number | string) => {
	const params = {
		"expand[0]": "status",
		"expand[1]": "roles",
		"expand[2]": "city",
		"expand[3]": "responsible",
		"expand[4]": "companies",
		"expand[5]": "requisite",
		"expand[6]": "requisite.agentContract",
		"expand[7]": "approveRequests",
		"expand[8]": "legalForm"
	};

	return GET(`users/${id}`, params);
};

export const getUsersWithAccesses = (auction_id: string | number) => {
	return GET(`auctions/${auction_id}/acl`);
};

export const setAccessUsersRequest = (data: any, auction_id: string | number) => {
	return POST(`auctions/${auction_id}/acl`, data);
};

export const deleteUserRequest = (data: any, auction_id: string | number) => {
	return DELETE(`auctions/${auction_id}/acl`, data);
};

export const blockUsersRequest = (ids: any) => {
	return POST("users/lock", { users: ids });
};

export const unlockUsers = (ids: any) => {
	return POST("users/unlock", { users: ids });
};

export const getTechSpecialists = () => {
	return GET("users?filters[roles][0]=tech-specialist");
};

export const postChangeUsersPassword = (userId: string | number, password: any) => {
	return POST(`users/${userId}/change-password`, { password });
};

export const postChangeProfilePassword = (password: any) => {
	return POST("auth/password/change", { password });
};

export const postActivatePromo = (code: any) => {
	return POST(`temporary-register-links/${code}/activate`);
};

export const postUserApprove = (params: any) => {
	return POST("users/approve", params);
};

export const getRequisitesTemplates = () => {
	return GET("requisites/templates");
};

export const getPermissionsView = () => {
	return GET("permissions/view");
};

export const getUsersReport = () => {
	return GET("users/report");
};
