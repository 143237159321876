import { observer } from "mobx-react-lite";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { getGroupUsersRequest, getUserGroupRequest } from "@api/user-groups";
import { Button } from "@components/button/button";
import { Loader } from "@components/loader/loader";
import { Pagination } from "@components/pagination/pagination";
import { Table } from "@components/table/table";
import { meStore } from "@mobx/me";

import { UsersGroupModal } from "./user-groups-modal/users-group-modal";

import "./user-group.scss";

const PER_PAGE = [25, 50, 100, 250, 500];

const columns = [
	{
		dataField: "first_name",
		text: "ФИО",
		sort: true,
		formatter: (cell, row) => (
			<Link to={`/user/${row.id}`}>
				{row.first_name} {row.last_name}{" "}
				{row.middle_name ? row.middle_name : ""}
			</Link>
		),
	},
	{
		dataField: "phone",
		text: "Телефон",
		sort: true,
	},
	{
		dataField: "status",
		text: "Статус",
		sort: true,
		formatter: (cell, row) => (row.status ? row.status.name : ""),
	},
	{
		dataField: "created_at",
		text: "Дата создания",
		formatter: (cell, row) => moment(row.created_at).format("DD.MM.YYYY"),
		sort: true,
	},
];

export const UserGroup = observer(() => {
	const { id } = useParams();

	const [group, setGroup] = useState();
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState();
	const [showModal, setShowModal] = useState(false);

	const requestUsers = (params) => {
		getGroupUsersRequest(id, params).then(setUsers);
	};

	useEffect(() => {
		getUserGroupRequest(id).then((resp) => {
			setGroup(resp);
			setLoading(false);
		});
		requestUsers({ page: 1, per_page: PER_PAGE[0] });
	}, []);

	if (loading) {
		return <Loader />;
	}

	return (
		<div className="group-wrapper">
			<div className="d-flex justify-content-end">
				{meStore.hasPermission("groups.edit") && (
					<Link
						to={`/user-group/${id}/edit`}
						className="btn btn-primary"
					>
						Редактировать
					</Link>
				)}
			</div>
			<div className="company-info mt-25px">
				<div className="d-flex justify-content-between w-50">
					<div className="name">
						<span>Название группы</span>
					</div>
					<div className="value">
						<span>{group.name}</span>
					</div>
				</div>
			</div>
			<div className="d-flex align-items-center justify-content-between mt-4">
				<div className="title">Пользователи</div>
				{meStore.hasPermission("groups.assign") && (
					<Button onClick={() => setShowModal(true)}>Изменить</Button>
				)}
			</div>
			{users?.data?.length > 0 ? (
				<div className="mt-4">
					<Table data={users.data} columns={columns} />
					<Pagination
						data={users}
						onChange={requestUsers}
						perPageList={PER_PAGE}
					/>
				</div>
			) : (
				<div className="mt-4">Пользователи еще не добавлены</div>
			)}
			{showModal && (
				<UsersGroupModal
					groupUsers={users}
					groupId={group?.id}
					show={true}
					onHide={() => setShowModal(false)}
					requestUsers={requestUsers}
				/>
			)}
		</div>
	);
});
