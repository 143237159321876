import { Button } from "@components/button/button";
import { VIEW_HORIZONTAL } from "@pages/current-trades/current-trades-filters/utils";
import { getCurrencySymbol } from "@utils/common";

import { LotCars } from "./lot-cars/lot-cars";

import { useState } from "react";

import { Modal } from "react-bootstrap";

import { auctionsStore } from "@mobx/auctions";
import clsx from "clsx";

import "./auction-down.scss";

export const LotCardBuyNowAuction = (props) => {
	const {
		lot,
		sellerable,
		auctionId,
		view,
		classes,
		isWinner,
		isLost,
		hideCars,
		elFavouriteBtn
	} = props;

	const [showModal, setShowModal] = useState(false);

	const onToggleModal = () => {
		setShowModal((prev) => !prev);
	};

	const onHandleBuyNow = () => {
		if (lot.is_active)
			auctionsStore.betAuction(auctionId, lot.id, lot.price);
	};

	const modal = () => {
		return (
			<Modal
				show={showModal}
				onHide={onToggleModal}
				className={clsx(
					"d-flex align-items-center justify-content-center modal-size-small"
				)}
			>
				<div className="head-text text-center mb-3">
					{lot?.sales[0]?.display_name}
				</div>
				<div className={"d-flex justify-content-center gap-2 mb-3"}>
					<b>Цена:</b>
					<b>{lot?.price} ₽</b>
				</div>
				<Button
					onClick={onHandleBuyNow}
					preset="primary"
					className={"mb-3"}
				>
					Купить
				</Button>
				<Button preset="secondary" onClick={onToggleModal}>
					Закрыть
				</Button>
			</Modal>
		);
	};

	const elBetButton = () => {
		const getClassNameBet = () => {
			if (isWinner) {
				return "btn__bought-by-me";
			}
			if (isLost) {
				return "btn__bought-by-not-me";
			}

			return "btn__active";
		};

		let text = (
			<div className="d-flex align-items-center justify-content-center">
				{lot.price.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}{" "}
				{getCurrencySymbol()} · Купить
			</div>
		);

		if (isWinner) {
			text = (
				<div className="d-flex align-items-center gap-5px justify-content-center">
					<img src="/img/icons/tick-icon.svg" />
					Куплено
				</div>
			);
		} else if (isLost) {
			text = "Продано";
		}

		return (
			<Button
				onClick={() => setShowModal(true)}
				className={`auction__bet-btn auction-buy-now__bet-btn ${getClassNameBet()}`}
				disabled={!lot.is_active}
			>
				{text}
			</Button>
		);
	};

	const elTop = () => {
		if (lot.finished_at || !lot.is_active) {
			return <div className="disabled-auctions" />;
		}
	};

	const elActions = () => {
		if (view === VIEW_HORIZONTAL) {
			return (
				<div className="actions">
					<div className="w-100 position-relative d-flex justify-content-between align-items-center">
						{elBetButton()}
						{elFavouriteBtn}
					</div>
				</div>
			);
		}

		return (
			<div className="actions">
				<div className="w-100 position-relative d-flex justify-content-between align-items-center gap-20px">
					{elBetButton()}
					{elFavouriteBtn}
				</div>
			</div>
		);
	};

	return (
		<div
			className={`lot-car-wrapper position-relative ${classes.join(" ")}`}
		>
			{elTop()}
			{!hideCars && (
				<LotCars lot={lot} view={view} auctionId={auctionId} sellerable={sellerable} />
			)}
			{elActions()}
			{modal()}
		</div>
	);
};
