import moment from "moment/moment";
import { Link } from "react-router-dom";

import { putApproveLot } from "@api/auctions";
import { Button } from "@components/button/button";
import { meStore } from "@mobx/me";
import {
	getTypeAuctionText,
	LOT_GREEN_STATUSES,
	LOT_GREY_STATUSES,
	LOT_ORANGE_STATUSES,
	LOT_RED_STATUSES,
} from "@utils/common";
import { formatValueWithSpaces } from "@utils/format";

export const getFinishedColumns = (auctionRequest) => {
	const getClassName = (status) => {
		if (LOT_GREEN_STATUSES.includes(status)) {
			return "lot-status green";
		}
		if (LOT_GREY_STATUSES.includes(status)) {
			return "lot-status grey";
		}
		if (LOT_RED_STATUSES.includes(status)) {
			return "lot-status red";
		}
		if (LOT_ORANGE_STATUSES.includes(status)) {
			return "lot-status orange";
		}
	};

	return [
		{
			dataField: "id",
			text: "№ ",
			headerStyle: () => {
				return { width: "115px" };
			},
			formatter: (cell, row) => (
				<div className="min-height">
					{meStore.hasPermission("auction.view.extended") ? (
						<Link
							to={`/sell/auction/${row?.id}`}
							className="table__file-blue-text cursor-pointer"
						>
							{row?.id}
						</Link>
					) : (
						<div>{row?.id}</div>
					)}
					<div className="table__file-extra-text">
						{getTypeAuctionText(row?.auction_type)}
					</div>
				</div>
			),
		},
		{
			dataField: "start_at",
			text: "Даты",
			headerStyle: () => {
				return { width: "100px" };
			},
			formatter: (cell, row) => (
				<div className="d-flex flex-column min-height gap-5px align-items-end">
					{row.start_at && (
						<div>
							<div>
								{moment(row.start_at).format("DD.MM.YYYY")}
							</div>
							<div className="table__file-extra-text">
								{moment(row.start_at).format("HH:mm")}
							</div>
						</div>
					)}
					{row?.finished_at && (
						<div className="d-flex">
							–
							<div>
								<div>
									{moment(row.finished_at).format(
										"DD.MM.YYYY"
									)}
								</div>
								<div className="table__file-extra-text">
									{moment(row.finished_at).format("HH:mm")}
								</div>
							</div>
						</div>
					)}
				</div>
			),
		},
		{
			dataField: "lot",
			text: "Лот",
			headerStyle: () => {
				return { width: "280px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="d-grid gap-5px min-height"
								>
									{lot.sales?.map((sale, index) => {
										return (
											<Link
												to={`/car/${sale.id}?auctionId=${lot.auction_id}&lotId=${lot.id}&lotSale=true`}
												key={`sale-${index}`}
												className="table__file-blue-text min-height"
											>
												{sale.car?.brand?.name}{" "}
												{sale.car?.model?.name}{" "}
												{sale.car?.year} г.в.{" "}
												{sale.car?.engine_volume}{" "}
												{sale.car?.transmission?.name}{" "}
												{sale.car?.vin}
											</Link>
										);
									})}
								</div>
							);
						})}
					</div>
				);
			},
		},
		{
			dataField: "Status_lot",
			text: "Статус",
			headerStyle: () => {
				return { width: "190px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									<div
										className={`${getClassName(
											lot?.status?.code
										)}`}
									>
										{lot.status?.name}
									</div>
								</div>
							);
						})}
					</div>
				);
			},
		},
		{
			dataField: "last_status_change",
			text: "Изменен",
			headerStyle: () => {
				return { width: "120px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									{lot.last_status_change?.created_at &&
										moment(
											lot.last_status_change?.created_at
										).format("DD.MM.YYYY HH:mm")}
								</div>
							);
						})}
					</div>
				);
			},
		},

		{
			dataField: "last-bet",
			text: "Макс. ставка",
			headerStyle: () => {
				return { width: "120px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									{lot.lastBet?.sum
										?.toString()
										.replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}
								</div>
							);
						})}
					</div>
				);
			},
		},
		{
			dataField: "starting_price",
			text: "Стартовая цена",
			headerStyle: () => {
				return { width: "140px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									{formatValueWithSpaces(lot.starting_price)}
								</div>
							);
						})}
					</div>
				);
			},
		},
		{
			dataField: "minimal_step",
			text: "Шаг торгов",
			headerStyle: () => {
				return { width: "140px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									{formatValueWithSpaces(lot.minimal_step)}
								</div>
							);
						})}
					</div>
				);
			},
		},
		{
			dataField: "minimal_price",
			text: "Минимальная цена",
			headerStyle: () => {
				return { width: "140px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height"
								>
									{formatValueWithSpaces(lot.minimal_price)}
								</div>
							);
						})}
					</div>
				);
			},
		},
		{
			dataField: "do",
			text: "Подтвержденная",
			headerStyle: () => {
				return { width: "170px" };
			},
			formatter: (cell, row) => {
				return (
					<div className="d-grid gap-5px">
						{row.lots.map((lot, index) => {
							return (
								<div
									key={`lot-${index}`}
									className="min-height confirmed-bet"
								>
									{lot?.actions?.approve &&
									lot?.status?.code ===
										"lot_waiting_approval" ? (
										<>
											<Button
												preset="primary"
												size="xs"
												className="btn-action__approve"
												onClick={() =>
													putApproveLot(
														lot.auction_id,
														lot.id,
														true
													).then(auctionRequest)
												}
											>
												<img
													src="/img/icons/tick-icon.svg"
													alt=""
												/>
												Подтвердить
											</Button>
											<Button
												preset="ghost"
												size="xs"
												className="d-flex gap-5px align-items-center"
												onClick={() =>
													putApproveLot(
														lot.auction_id,
														lot.id,
														false
													).then(auctionRequest)
												}
											>
												<img
													src="/img/icons/cancel-black-icon.svg"
													alt=""
												/>
												Отклонить
											</Button>
										</>
									) : (
										lot.winning_bet?.sum
									)}
								</div>
							);
						})}
					</div>
				);
			},
		},
	];
};

export const getActiveColumns = [
	{
		dataField: "id",
		text: "№ ",
		headerStyle: () => {
			return { width: "115px" };
		},
		formatter: (cell, row) => (
			<div className="min-height">
				{meStore.hasPermission("auction.view.extended") ? (
					<Link
						to={`/sell/auction/${row?.id}`}
						className="cursor-pointer table__file-blue-text"
					>
						{row?.id}
					</Link>
				) : (
					<div>{row?.id}</div>
				)}
				<div className="table__file-extra-text">
					{getTypeAuctionText(row?.auction_type)}
				</div>
			</div>
		),
	},
	{
		dataField: "start_at",
		text: "Даты",
		headerStyle: () => {
			return { width: "100px" };
		},
		formatter: (cell, row) => (
			<div className="d-flex flex-column min-height gap-5px justify-content-end align-items-end">
				{row.start_at && (
					<div>
						<div>{moment(row.start_at).format("DD.MM.YYYY")}</div>
						<div className="table__file-extra-text">
							{moment(row.start_at).format("HH:mm")}
						</div>
					</div>
				)}
				{row?.planned_finish_at && (
					<div className="d-flex">
						–
						<div>
							<div>
								{moment(row.planned_finish_at).format(
									"DD.MM.YYYY"
								)}
							</div>
							<div className="table__file-extra-text">
								{moment(row.planned_finish_at).format("HH:mm")}
							</div>
						</div>
					</div>
				)}
			</div>
		),
	},
	{
		dataField: "lot",
		text: "Лот",
		headerStyle: () => {
			return { width: "280px" };
		},
		formatter: (cell, row) => {
			return (
				<div className="d-grid gap-5px">
					{row.lots.map((lot, index) => {
						return (
							<div
								key={`lot-${index}`}
								className="d-grid gap-5px"
							>
								{lot.sales?.map((sale, index) => {
									return (
										<Link
											to={`/car/${sale.id}?auctionId=${lot.auction_id}&lotId=${lot.id}&lotSale=true`}
											key={`sale-${index}`}
											className="table__file-blue-text min-height"
										>
											{sale.car?.brand?.name}{" "}
											{sale.car?.model?.name}{" "}
											{sale.car?.year} г.в.{" "}
											{sale.car?.engine_volume}{" "}
											{sale.car?.transmission?.name}{" "}
											{sale.car?.vin}
										</Link>
									);
								})}
							</div>
						);
					})}
				</div>
			);
		},
	},
	{
		dataField: "last-bet",
		text: "Текущая ставка",
		headerStyle: () => {
			return { width: "100px" };
		},
		formatter: (cell, row) => {
			return (
				<div className="d-grid gap-5px">
					{row.lots.map((lot, index) => {
						return (
							<div key={`lot-${index}`} className="min-height">
								{lot.lastBet?.sum
									?.toString()
									.replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}
							</div>
						);
					})}
				</div>
			);
		},
	},
];
