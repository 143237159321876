import Input from "@components/Forms/Input/Input";
import Select from "@components/Forms/Select/Select";
import { formToJSONWithEmpryString } from "@utils/formToJSON";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useRef, useState } from "react";

import { Loader } from "@components/loader/loader";
import { dictionariesStore } from "@mobx/dictionaries";

import { getCarSaleFillableFields } from "@api/cars";
import { InputRequisitesSearch } from "@components/Forms/input-requisites-search/input-requisites-search";
import { Accordion } from "react-bootstrap";
import "./car-form-components.scss";

import CheckboxGroups from "./components/CheckboxGroups/CheckboxGroups";
import FieldCarForm from "./components/FieldCarForm/FieldCarForm";
import {
	booleanOptionsRadio,
	getGeneralInformationDefaultValues,
	inputStringTypeField
} from "./utils";

import { postCreateCarSale, putUpdateCarSale } from "@api/cars";
import { carSaleStore } from "@mobx/car-sale";
import { meStore } from "../../../mobx/me";
import { ModalConfirm } from "./components/modal-confirm";

export const GeneralInformation = observer(
	({
		 onStepChange,
		 carData,
		 edit,
		 showConfirmPopup,
		 onConfirmChangeStep
	 }) => {
		const dictionaries = {
			car_types: dictionariesStore.dictionaries.car_types,
			areas: dictionariesStore.dictionaries.areas
		};
		const [loading, setLoading] = useState(edit);
		const [currentCarType, setCurrentCarType] = useState();
		const [groups, setGroups] = useState([]);
		const [error, setError] = useState();
		const [defaultValue, setDefaultValue] = useState();
		const [areaId, setAreaId] = useState();
		const user = meStore.data;
		const [changedForm, setChangedForm] = useState(false);
		const formRef = useRef();

		useEffect(() => {
			if (!dictionaries.car_types && !edit) {
				dictionariesStore.request("car_types");
			}
			setLoading(false);
			dictionariesStore.clear("car_models");
		}, []);

		useEffect(() => {
			if (edit && carData) {
				setCurrentCarType({
					label: carData?.car?.type?.name,
					value: carData?.car?.type.key
				});
				setAreaId(getGeneralInformationDefaultValues(carData).area_id);
			}
			setDefaultValue(getGeneralInformationDefaultValues(carData));
		}, [carData]);

		useEffect(() => {
			if (currentCarType?.value) {
				getCarSaleFillableFields(currentCarType?.value).then((resp) => {
					if (resp.groups) {
						resp.groups.forEach((group) => {
							group.fields.forEach((x) => {
								if (
									x.type === "dictionary" &&
									x.dictionary !== "car_models"
								) {
									dictionariesStore.request(x.dictionary, {
										filters: {
											car_type_key: currentCarType?.value
										},
										params: {
											"orders[name]": "asc"
										}
									});
								}
								x.reset = false;
							});
						});
						setGroups(resp.groups);
					}
				});
			}
		}, [currentCarType]);

		const onChangeDictionaryHandler = (type, value) => {
			if (type === "car_brands") {
				setGroups(groups.map(x => {
					x.fields.forEach(y => {
						if (y.key === "car_model_id") y.reset = !y.reset;
					});
					return x;
				}));
				dictionariesStore.request("car_models", {
					filters: {
						car_brand_id: value.value,
						car_type_key: currentCarType.value
					}
				});
			}
			if (type === "cities") {
				setAreaId(dictionaries.areas.find(x => x.value === value.area_id));
			}
		};

		const saveRequest = async (data) => {
			return edit
				? putUpdateCarSale(carData?.id, data)
				: postCreateCarSale(data);
		};

		const onSubmit = async (e) => {
			if (e) e.preventDefault();
			let data = formToJSONWithEmpryString(e ? e.target : formRef.current);
			try {
				const response = await saveRequest(data);

				if (response?.error) {
					setError(
						response.error?.response?.data?.errors ||
						response.error?.response?.data?.message
					);

					setTimeout(() => {
						setError(null);
					}, 5000);
				} else {
					await carSaleStore.fetchCar(response.id);

					onStepChange();
				}
			} catch (err) {
				console.error(err);
			}
		};

		const onChangeForm = () => {
			setChangedForm(true);
		};

		// FIXME: пока оставил, нужно разобраться, нужны ли поля эти при создании ТС
		// const context = useForm({ defaultValues, mode: "onChange" });
		// const values = context.watch();
		// const { isDirty } = context.formState;

		// useEffect(() => {
		// 	if (edit && carData?.car && groups.length) {
		// 		const newDefaultValues =
		// 			getGeneralInformationDefaultValues(carData);

		// 		context.reset(newDefaultValues);

		// 		setDefaultValues(newDefaultValues);
		// 		setLoading(false);
		// 	}
		// }, [JSON.stringify(carData), groups, edit]);

		// useEffect(() => {
		// 	if (context.formState.dirtyFields.city_id) {
		// 		const newArea = dictionariesStore.dictionaries.areas.find(
		// 			(item) => item.value === values.city_id.area_id
		// 		);

		// 		if (newArea) {
		// 			context.setValue("area_id", newArea, {
		// 				shouldValidate: true,
		// 			});
		// 		}
		// 	}
		// }, [values.city_id]);

		const arrFields = useMemo(() => {
			let arr = [];

			if (carData?.metadata) {
				arr = [
					...carData.metadata.allowed_fields.update,
					...carData.metadata.car.allowed_fields
				];
			}

			return arr;
		}, [JSON.stringify(carData?.metadata)]);

		const getDisabled = (field) => {
			return edit && !arrFields?.includes(field);
		};

		return (
			<div>
				<div className="title mb-4">Общая информация</div>
				{loading ? (
					<Loader />
				) : (
					<form
						ref={formRef}
						onSubmit={onSubmit}
						onChange={onChangeForm}
					>
						<div className="all-info-wrapper">
							<FieldCarForm
								label="Тип авто"
								indentBottom={currentCarType?.value}
							>
								<Select
									name={"car_type_key"}
									options={dictionaries.car_types || []}
									placeholder={"Выберите тип ТС"}
									isMulty={false}
									defaultValue={currentCarType}
									onChange={setCurrentCarType}
									сlearable={false}
									disabled={edit}
								/>
							</FieldCarForm>
							<Accordion alwaysOpen defaultActiveKey={[0, 1]}>
								{groups.map((group, index) => (
									<Accordion.Item
										key={`${group.name}-${index}`}
										eventKey={index}
									>
										<Accordion.Header>
											{group.name}
										</Accordion.Header>
										<Accordion.Body>
											{group.fields.map((field) => {
												const {
													key,
													name,
													is_required,
													type,
													dictionary,
													reset
												} = field;

												if (type === "dictionary") {
													return (
														<FieldCarForm
															label={name}
															key={key}
															indentBottom
															required={
																is_required
															}
														>
															<Select
																reset={reset}
																name={key}
																options={
																	dictionariesStore
																		.dictionaries[
																		dictionary
																		] || []
																}
																placeholder={
																	"Выберите значение"
																}
																defaultValue={
																	key === "area_id" ? areaId : defaultValue[key]
																}
																onChange={(e) =>
																	onChangeDictionaryHandler(
																		dictionary,
																		e
																	)
																}
																required={
																	is_required
																}
																disabled={getDisabled(key)}
															/>
														</FieldCarForm>
													);
												}

												if (type === "boolean") {
													return (
														<FieldCarForm
															label={name}
															key={key}
															indentBottom
															required={
																is_required
															}
														>
															<CheckboxGroups
																name={key}
																options={
																	booleanOptionsRadio
																}
																required={
																	is_required
																}
																defaultValue={
																	edit &&
																	defaultValue[
																		key
																		]
																}
																disabled={getDisabled(key)}
															/>
														</FieldCarForm>
													);
												}

												if (key === "owner") {
													return (
														<FieldCarForm
															label={name}
															key={key}
															indentBottom
															required={
																is_required
															}
														>
															<InputRequisitesSearch
																name={
																	"owner_id"
																}
																placeholder={
																	"Продавец"
																}
																typeId={2}
																defaultValue={{
																	label:
																		edit ?
																			defaultValue[
																				key
																				]
																				?.display_name : "",
																	value:
																		edit ?
																			defaultValue[
																				key
																				]
																				?.id : ""
																}}
																required={
																	is_required
																}
																disabled={getDisabled(key)}
															/>
															<input
																type="hidden"
																name="owner_type"
																value={"company"}
																disabled={getDisabled(key)}
															/>
														</FieldCarForm>
													);
												}

												return (
													<FieldCarForm
														label={name}
														key={key}
														indentBottom
														required={
															is_required
														}
													>
														<Input
															type={
																inputStringTypeField[
																	type
																	]
															}
															placeholder={name}
															name={key}
															required={
																is_required
															}
															value={
																edit &&
																defaultValue[
																	key
																	]
															}
														/>
													</FieldCarForm>
												);
											})}
										</Accordion.Body>
									</Accordion.Item>
								))}
							</Accordion>
						</div>
						{error && (
							<div className="mt-2 mb-2 alert alert-danger">
								{JSON.stringify(error, null, " ")}
							</div>
						)}
						<div className="d-flex align-items-center gap-3 mt-3 mob-button-wrapper">
							<button
								className="btn btn-primary flex-grow-1"
								type="submit"
							>
								Сохранить
							</button>
							{edit && (
								<button
									className="btn btn-light flex-grow-1"
									type="button"
									onClick={onStepChange}
								>
									Пропустить
								</button>
							)}
						</div>
					</form>
				)}
				{showConfirmPopup && (
					<ModalConfirm
						onSave={onSubmit}
						onClose={onConfirmChangeStep}
						isDirty={changedForm}
					/>
				)}
			</div>
		);
	}
);
