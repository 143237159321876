import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
	Link,
	Navigate,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";

import {
	getAuctionsLotsTransitions,
	getAuctionsTransitions,
	getAuctionsTypesRequest,
	getSingleAuctionRequest,
	postChangeAuctionLotTransition,
	postChangeAuctionTransition,
} from "@api/auctions";
import { getFileLink } from "@api/request";
import { Dropdown } from "@components/dropdown/dropdown";
import { DropdownButton } from "@components/dropdown/dropdown-button";
import { IconArrowDown } from "@components/icons/arrow-down";
import { Loader } from "@components/loader/loader";
import { Table } from "@components/table/table";
import { TransitionConfirmPopup } from "@components/transitiion-confirm-popup/transition-confirm-popup";
import { meStore } from "@mobx/me";
import { EditWinnerModal } from "@pages/auction/auction-edit-trades-result-modal/edit-winner-modal";
import { AuctionTradesHistoryModal } from "@pages/auction/auction-trades-history-modal/auction-trades-history-modal";
import {
	getBetsColumns,
	getClassName,
	getLotsColumns,
} from "@pages/auction/utils";
import { NO_PERMISSION_URL } from "@utils/common";
import { formatDateWithTime } from "@utils/date";

import { AuctionReports } from "./auction-reports/auction-reports";
import { LastBetDeleteModal } from "./last-bet-delete-modal/last-bet-delete-modal";

import { getUsersWithAccesses } from "@api/users";
import moment from "moment";
import BtnText from "../../components/btn/BtnText/BtnText";
import IconArrowBack from "../../components/icons/IconArrowBack";
import AuctionUsers from "./AuctionUsers/AuctionUsers";
import "./auction.scss";

const LOTS = "lots";
const BETS = "bets";
const REPORTS = "reports";
const GROUP = "group";

const TABS = [
	{ id: LOTS, title: "Лоты" },
	{ id: BETS, title: "Автоставки" },
	{ id: REPORTS, title: "Отчеты" },
];

export const Auction = observer(({ isSell }) => {
	const { id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState(
		searchParams.get("tab") || TABS[0].id
	);
	const [columns, setColumns] = useState([]);
	const [auctionTypes, setAuctionTypes] = useState();
	const [loading, setLoading] = useState(true);
	const [auction, setAuction] = useState(true);
	const [lotId, setLotId] = useState();
	const [showTradesHistoryModal, setShowTradesHistoryModal] = useState(false);
	const [showAuctionEditTradesModal, setShowAuctionEditTradesModal] =
		useState(false);
	const [isAuctionEditTradesWinner, setIsAuctionEditTradesWinner] =
		useState(false);
	const [lastBetDelete, setLastBetDelete] = useState();
	const [showTransitionPopup, setShowTransitionPopup] = useState(false);
	const [accessUserGroup, setAccessUserGroup] = useState([]);
	const [usersGroupId, setUsersGroupId] = useState(null);

	const getUsersGroup = () => {
		return [
			{
				dataField: "name",
				text: "Имя",
				sort: true,
				formatter: (cell, row) => (
					<div>
						{row.owner.indexOf("G") === -1 ? (
							<Link to={`/user/${row.owner_model?.id}`}>
								{row.owner_model?.display_name}
							</Link>
						) : (
							<UserLink
								name={row.owner_model?.name}
								id={row.owner_model?.id}
							/>
						)}
					</div>
				),
			},
			{
				dataField: "created_at",
				text: "Дата создания",
				formatter: (cell, row) =>
					moment(row.owner_model?.created_at).format("DD-MM-YYYY"),
				sort: true,
			},
			{
				dataField: "groups_users",
				text: "Группы / Пользователи",
				formatter: (cell, row) => (
					<div>
						{row.owner.indexOf("G") === -1
							? "Пользователь"
							: "Группа"}
					</div>
				),
			},
		];
	};

	const UserLink = (props) => {
		return (
			<div className="link" onClick={() => onGetUsers(props.id)}>
				{props.name}
			</div>
		);
	};

	const onGetUsers = (id) => {
		setUsersGroupId(id);
	};

	const handleShowLastBetDeleteModal = (data) => {
		setShowTradesHistoryModal(false);
		setLastBetDelete(data);
	};

	const requestAuction = () => {
		getSingleAuctionRequest(id).then((resp) => {
			if (!resp.message) {
				setAuction(resp);
				setLoading(false);
			}
		});
	};

	const [reportLink, setReportLink] = useState("");
	const [transitions, setTransitions] = useState({});
	const [lotsTransitions, setLotsTransitions] = useState({});
	const navigate = useNavigate();

	const changeStatusLot = ({ auctionId, lotId, status }) => {
		postChangeAuctionLotTransition(auctionId, lotId, status).then(() => {
			requestAuction();
			setShowTransitionPopup(false);
		});
	};

	const elStatusLot = (auctionId, lotId, statusCode, statusName) => {
		const options = [];
		const isShowDropdown =
			statusCode !== "lot_waiting_approval" &&
			lotsTransitions &&
			lotsTransitions?.[statusCode];

		if (isShowDropdown) {
			options.push({
				label: "Сменить статус",
				value: "",
				disabled: true,
			});

			lotsTransitions[statusCode]?.forEach((item) => {
				options.push({
					label: item.status_name,
					value: item.status,
					onClick: () => {
						if (item.confirmation_required) {
							setShowTransitionPopup(item);
						} else {
							changeStatusLot({
								auctionId,
								lotId,
								status: item.status,
							});
						}
					},
				});
			});
		}

		return (
			<Dropdown
				customToggle={
					<button
						type="button"
						className={clsx(getClassName(statusCode), {
							"cursor-default": !isShowDropdown,
						})}
					>
						{statusName}
						{isShowDropdown && <IconArrowDown />}
					</button>
				}
				options={options}
				menuPlacement="top"
			/>
		);
	};

	useEffect(() => {
		getAuctionsTransitions().then((resp) => setTransitions(resp));

		getAuctionsLotsTransitions().then((resp) => {
			setLotsTransitions(resp);
		});

		getAuctionsTypesRequest().then((resp) => setAuctionTypes(resp));

		getUsersWithAccesses(id).then((resp) => {
			if (Array.isArray(resp) && resp?.length > 0)
				setAccessUserGroup(resp.filter((x) => x.has_permission));
		});
	}, []);

	useEffect(() => {
		requestAuction();
	}, []);

	useEffect(() => {
		if (activeTab === LOTS && lotsTransitions) {
			setColumns(
				getLotsColumns(
					id,
					requestAuction,
					setLotId,
					setShowTradesHistoryModal,
					setShowAuctionEditTradesModal,
					setIsAuctionEditTradesWinner,
					elStatusLot,
					auction.status?.code
				)
			);
		} else if (activeTab === BETS) {
			setColumns(getBetsColumns());
		}
	}, [activeTab, lotsTransitions, auction?.status?.code]);

	if (meStore.noPermission("admin.auctions.view") && !isSell) {
		return <Navigate to={NO_PERMISSION_URL} />;
	}

	if (meStore.data === null || loading) {
		return <Loader />;
	}

	const renderTabs = () => {
		const filteredTabs =
			isSell || auction?.auction_type !== "parallel_increase"
				? TABS?.filter((tab) => tab?.id !== "bets")
				: TABS;
		return (
			<div className="buy-page-wrapper">
				<div className="tabs d-flex">
					{filteredTabs.map((tab) => (
						<div key={tab.id}>
							<button
								onClick={() => {
									setActiveTab(tab.id);
									setSearchParams({ tab: tab.id });
								}}
								className={`tab-btn ${
									tab.id === activeTab ? "tab-btn-active" : ""
								}`}
								type="button"
							>
								{tab.title}
							</button>
						</div>
					))}
					{!!accessUserGroup?.length && (
						<div>
							<button
								onClick={() => {
									setUsersGroupId(null);
									setActiveTab(GROUP);
									setSearchParams({ tab: GROUP });
								}}
								className={`tab-btn ${
									GROUP === activeTab ? "tab-btn-active" : ""
								}`}
								type="button"
							>
								Группы / Пользователи
							</button>
						</div>
					)}
				</div>
			</div>
		);
	};

	const url = `${window.location.origin}/auth/code/${auction?.active_temporary_registration_link?.code}`;

	const renderContent = () => {
		if (activeTab === LOTS || activeTab === BETS) {
			return auction?.lots.length && columns.length ? (
				<div className="mt-4">
					<Table
						classes="table-not-fixed"
						data={auction.lots}
						columns={columns}
					/>
				</div>
			) : (
				"Лотов нет"
			);
		}
		if (activeTab === REPORTS) {
			return <AuctionReports auctionId={id} />;
		}
		if (activeTab === GROUP) {
			return (
				<div className="mb-4">
					{!!usersGroupId ? (
						<>
							<BtnText
								className="mb-2"
								onClick={() => setUsersGroupId(null)}
								iconBefore={<IconArrowBack />}
							>
								Назад к группам
							</BtnText>
							<AuctionUsers id={usersGroupId} />
						</>
					) : (
						<Table
							data={accessUserGroup}
							columns={getUsersGroup()}
						/>
					)}
				</div>
			);
		}
	};

	const getReportLink = () => {
		if (auction?.is_finished) {
			getFileLink(`auctions/${id}/result_report`, "xlsx").then((resp) => {
				setReportLink(resp);
			});
		}
	};

	const elActions = () => {
		const options = [];

		if (meStore.hasPermission("admin.auctions.edit")) {
			options.push({
				label: "Редактировать",
				value: "edit",
				onClick: () => navigate(`/auction/${auction.id}/edit`),
			});
		}

		if (meStore.hasPermission("auctions.transition")) {
			transitions[auction.status.code]?.forEach((item) => {
				options.push({
					label: item.status_name,
					value: item.status,
					onClick: () => {
						postChangeAuctionTransition(
							auction.id,
							item.status
						).then(requestAuction);
					},
				});
			});
		}

		if (options.length) {
			return <DropdownButton options={options} btnText="Действия" />;
		}
	};

	return (
		<div className="single-auction-wrapper">
			{auction && (
				<div>
					<div>
						<div className="d-flex justify-content-between align-items-center mb-25px">
							<div className="auction-name">
								Аукцион №{auction.id}
							</div>
							<div className="d-flex align-items-center">
								{auction?.is_finished &&
									(reportLink ? (
										<a
											href={reportLink?.url}
											download={reportLink?.download}
											className="reports-btn"
										>
											Скачать отчет
										</a>
									) : (
										<button
											type="button"
											className="reports-btn"
											onClick={() => getReportLink()}
										>
											Отчет с результатами аукциона
										</button>
									))}
								{!isSell && elActions()}
							</div>
						</div>
						<div className="auction-info">
							<div className="d-grid grid-columns-30-70 auction-info__field-wrapper">
								<div className="name">Тип аукциона</div>
								<div>
									{auctionTypes?.map((type, index) => {
										return (
											<div key={`${type.key}-${index}`}>
												{type.key ===
													auction?.auction_type &&
													type.title}
											</div>
										);
									})}
								</div>
							</div>
							<div className="d-grid grid-columns-30-70 auction-info__field-wrapper">
								<div className="name">Статус</div>
								<div>{auction.status?.name || "-"}</div>
							</div>
							<div className="d-grid grid-columns-30-70 auction-info__field-wrapper">
								<div className="name">Время начала</div>
								<div>
									{auction.start_at
										? formatDateWithTime(auction.start_at)
										: "-"}
								</div>
							</div>
							<div className="d-grid grid-columns-30-70 auction-info__field-wrapper">
								<div className="name">Время окончания</div>
								<div>
									{auction.finished_at
										? formatDateWithTime(
												auction.finished_at
										  )
										: auction.planned_finish_at
										? formatDateWithTime(
												auction.planned_finish_at
										  )
										: "-"}
								</div>
							</div>
							<div className="d-grid grid-columns-30-70 auction-info__field-wrapper">
								<div className="name">Временный доступ</div>
								{auction?.active_temporary_registration_link
									?.code ? (
									<div className="break-word">
										<a
											href={url}
											target="_blank"
											rel="noreferrer"
										>
											{url}
										</a>
										<img
											src="/img/icons/icon-copy.svg"
											alt=""
											onClick={() =>
												navigator.clipboard.writeText(
													url
												)
											}
											className="cursor-pointer ml-5px"
										/>
									</div>
								) : (
									"-"
								)}
							</div>
							<div className="d-grid grid-columns-30-70 auction-info__field-wrapper">
								<div className="name">Продавец</div>
								{auction.sellerable && (
									<Link
										to={`/${
											auction.sellerable.parent_id
												? "department"
												: "company"
										}/${auction.sellerable.id}`}
									>
										{auction.sellerable.display_name}
									</Link>
								)}
							</div>
							<div className="d-grid grid-columns-30-70 auction-info__field-wrapper">
								<div className="name">Логотип</div>
								<div className="value">
									{auction.sellerable?.logo?.url ? (
										<a
											href={auction.sellerable?.logo?.url}
											target="_blank"
											rel="noreferrer"
										>
											<img
												src={
													auction.sellerable?.logo
														?.url
												}
												alt={
													auction.sellerable?.logo
														?.original_name
												}
												className="image"
											/>
										</a>
									) : (
										"-"
									)}
								</div>
							</div>
							<div className="d-grid grid-columns-30-70 auction-info__field-wrapper">
								<div className="name">
									Ответственный менеджер
								</div>
								{auction.responsible && (
									<Link
										to={`/user/${auction.responsible.id}`}
									>
										{auction.responsible.display_name}
									</Link>
								)}
							</div>
						</div>
					</div>
					{renderTabs()}
					{renderContent()}
				</div>
			)}
			{showTradesHistoryModal && (
				<AuctionTradesHistoryModal
					isFinishedAuction={auction?.is_finished}
					auctionId={id}
					lotId={lotId}
					handleClose={() => setShowTradesHistoryModal(false)}
					handleShowLastBetDeleteModal={handleShowLastBetDeleteModal}
				/>
			)}
			{lastBetDelete && (
				<LastBetDeleteModal
					auctionId={id}
					bet={lastBetDelete}
					handleClose={(needUpdateAuction) => {
						if (needUpdateAuction) {
							requestAuction();
						}

						setLastBetDelete(false);
					}}
				/>
			)}
			{showAuctionEditTradesModal && (
				<EditWinnerModal
					lot={showAuctionEditTradesModal}
					isEditWinner={isAuctionEditTradesWinner}
					setIsAuctionEditTradesWinner={setIsAuctionEditTradesWinner}
					handleClose={(needUpdateAuction) => {
						if (needUpdateAuction) {
							requestAuction();
						}
						setShowAuctionEditTradesModal(false);
					}}
				/>
			)}
			{showTransitionPopup && (
				<TransitionConfirmPopup
					handleClose={() => setShowTransitionPopup(false)}
					transition={showTransitionPopup}
					auctionId={id}
					lotId={lotId}
					changeStatusLot={changeStatusLot}
				/>
			)}
		</div>
	);
});
