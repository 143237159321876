import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { Table } from "@components/table/table";
import { Pagination } from "@components/pagination/pagination";
import { getAuctionBetsStepsRequest } from "@api/auctions";
import { getColumns } from "@pages/auction/auction-trades-history-modal/utils";

import "./auction-trades-history-modal.scss";

export const AuctionTradesHistoryModal = ({
											  isFinishedAuction,
											  auctionId,
											  lotId,
											  handleClose,
											  handleShowLastBetDeleteModal
										  }) => {
	const [lotBets, setLotBets] = useState(null);
	const autoBetsRequest = (params) => {
		getAuctionBetsStepsRequest(auctionId, lotId, { ...params, per_page: 10 })
			.then((resp) => {
				setLotBets(resp);
			});
	};

	useEffect(() => {
		autoBetsRequest();
	}, []);

	return (
		<div className="position-relative">
			<Modal
				show={true}
				onHide={handleClose}
				className="d-flex align-items-center justify-content-center auction-trades-history-modal-wrapper"
			>
				<div className="d-flex justify-content-between">
					<div className="title">Ход торгов</div>
					<img src="/img/icons/close-icon.svg" alt="" className="cursor-pointer" onClick={handleClose} />
				</div>
				{lotBets ? (
					<>
						<div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
							<Table
								data={lotBets.data}
								columns={getColumns(handleShowLastBetDeleteModal, isFinishedAuction)}
							/>
						</div>
						<Pagination
							data={lotBets}
							onChange={(params) => autoBetsRequest(params)}
						/>
					</>
				) : "Нет ставок"}
			</Modal>
		</div>
	);
};
