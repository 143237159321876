import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { Link, Navigate, useParams, useSearchParams } from "react-router-dom";

import { getAuctionLotsSales } from "@api/auctions";
import {
	getCarFields,
	getCarSalePhotos,
	getCarSaleTransitions,
	getImportDefectsRequest,
	getSingleCar,
	getStatusReportsElementsRequest,
	postChangeStatusCarTransition
} from "@api/cars";
import { AuctionIndicatorWithTimer } from "@components/auction-indicator/auction-indicator";
import { Dropdown } from "@components/dropdown/dropdown";
import { Gallery } from "@components/gallery/gallery";
import { IconArrowDown } from "@components/icons/arrow-down";
import { Loader } from "@components/loader/loader";
import { Slider } from "@components/slider/slider";
import { TransitionConfirmPopup } from "@components/transitiion-confirm-popup/transition-confirm-popup";
import useMediaQuery from "@hooks/use-media-query";
import { auctionsStore } from "@mobx/auctions";
import { meStore } from "@mobx/me";
import { pusherStore } from "@mobx/pusher";
import { getClassName } from "@pages/car/utils";
import { NO_PERMISSION_URL } from "@utils/common";

import { CarSummary } from "./car-info/car-summary";
import { CarTabsInfo } from "./car-info/car-tabs-info";
import { CarLotInfo } from "./car-lot-info";

import "./car.scss";
import LogoSeller from "@components/utils/LogoSeller/LogoSeller";
import CarLotComment from "@pages/car/CarLotComment/CarLotComment";

export const Car = observer(() => {
	const { id } = useParams();

	const [defects, setDefects] = useState();
	const [transitions, setTransitions] = useState({});
	const [carSaleInfo, setCarSaleInfo] = useState();
	const [startIndex, setStartIndex] = useState();
	const [showGallery, setShowGallery] = useState(false);
	const [auctionId, setAuctionId] = useState();
	const [lotId, setLotId] = useState();
	const [showTransitionPopup, setShowTransitionPopup] = useState(false);
	const [fields, setFields] = useState([]);
	const [photos, setPhotos] = useState([]);
	const [auction, setAuction] = useState();

	const [searchParams] = useSearchParams();
	const isLotSale = searchParams.get("lotSale") === "true";
	const isMobile = useMediaQuery();
	const user = meStore.data;

	const reports = carSaleInfo?.status_reports;
	const lastReportId =
		carSaleInfo?.last_closed_status_report?.id ||
		(reports?.length && reports[reports.length - 1].id) ||
		null;

	useEffect(() => {
		getCarFields(id).then(setFields);
		getCarSalePhotos(id).then(setPhotos);
	}, [id]);

	useEffect(() => {
		setAuction(
			auctionsStore.auctions.data?.find((item) => item.id === auctionId)
		);
	}, [auctionsStore.auctions.data]);

	const carPhotos = photos.flatMap((item) => {
		return item.photos.map((photo) => {
			return {
				original: photo.thumbnails_urls?.middle || photo.file.url,
				thumbnail: photo.thumbnails_urls?.small,
				description: item.name
			};
		});
	});

	const getCarSale = () => {
		let request;

		if (isLotSale) {
			request = getAuctionLotsSales(
				searchParams.get("auctionId"),
				searchParams.get("lotId"),
				id
			);
		} else if (
			meStore.hasPermission("admin.sales.view") ||
			meStore.hasPermission("sales.view")
		) {
			request = getSingleCar(id);
		}

		request?.then((resp) => {
			setCarSaleInfo(resp);
		});
	};

	useEffect(() => {
		if (lastReportId) {
			const request = carSaleInfo.is_imported
				? getImportDefectsRequest(carSaleInfo.id, 1)
				: getStatusReportsElementsRequest(lastReportId, 1);

			request.then(setDefects).catch(console.error);
		}
	}, [lastReportId]);

	useEffect(() => {
		getCarSaleTransitions().then((resp) => setTransitions(resp));

		if (isLotSale) {
			const search = new URLSearchParams(window.location.search);
			const auctionIdParam = search.get("auctionId");
			const lotIdParam = search.get("lotId");

			if (auctionIdParam && lotIdParam) {
				auctionsStore.getAuction(auctionIdParam);
				setAuctionId(Number(auctionIdParam));
				setLotId(Number(lotIdParam));
			}
		}
	}, []);

	const lot = useMemo(() => {
		return auction?.lots?.find((lot) => lot.id === lotId);
	}, [auction, window.location.search]);

	useEffect(() => {
		if (meStore.data) {
			getCarSale();
		}
	}, [meStore.data]);

	useEffect(() => {
		if (isLotSale && user.user.id) {
			pusherStore.subscribeAuctions({ userId: user.user.id });
		}

		return () => {
			isLotSale && pusherStore.disconnect();
		};
	}, [user.user.id]);

	if (
		meStore.noPermission("admin.sales.view") &&
		meStore.noPermission("sales.view") &&
		!searchParams.get("lotSale")
	) {
		return <Navigate to={NO_PERMISSION_URL} />;
	}

	if (!carSaleInfo || meStore.data === null) {
		return <Loader />;
	}

	const changeStatusCar = ({ status }) => {
		postChangeStatusCarTransition(id, status).then(() => {
			getCarSale();
			setShowTransitionPopup(false);
		});
	};

	const elActions = () => {
		const options = [];
		const statusCode = carSaleInfo?.status?.code;

		if (transitions?.[statusCode]) {
			options.push({
				label: "Сменить статус",
				value: "",
				disabled: true,
				className: getClassName(statusCode)
			});

			transitions[statusCode]?.forEach((item) => {
				options.push({
					label: `${item.status_name} ${
						statusCode === "sale_under_review" ? " (прошел проверку)" : ""
					}`,
					value: item.status,
					onClick: () => {
						if (item.confirmation_required) {
							setShowTransitionPopup(item);
						} else {
							changeStatusCar({ status: item.status });
						}
					}
				});
			});
		}

		if (carSaleInfo?.status) {
			return (
				<div className="d-flex actions mob-button-width">
					<div className="d-flex align-items-center action">
						<Dropdown
							customToggle={
								<button type="button" className={getClassName(statusCode)}>
									{carSaleInfo?.status?.name}
									{transitions[statusCode] && <IconArrowDown />}{" "}
								</button>
							}
							options={options}
						/>
					</div>
				</div>
			);
		}
	};

	const elEditButton = () => {
		if (
			!isLotSale &&
			(carSaleInfo?.status?.code === "sale_new" ||
				carSaleInfo?.status?.code === "sale_new_car")
		) {
			return (
				<Link to={`/car/${id}/edit`} className="car-checks__btn">
					<img src="/img/icons/edit-icon.svg" alt="" />
					Редактировать
				</Link>
			);
		}
	};

	return carSaleInfo ? (
		<>
			<div className="single-car-wrapper">
				{isLotSale && auction?.is_active && lot?.is_active && (
					<div className="single-car-wrapper__lot-info-top">
						{auction?.auction_type === "sequential_decrease"
							? "До понижения стоимости осталось:"
							: "Время до завершения:"}
						<AuctionIndicatorWithTimer
							auction={auction}
							expiresAt={lot.expires_at}
							isMobile={isMobile}
						/>
					</div>
				)}
				{isMobile && lotId && (
					<Link
						to="/current-trades"
						className="single-car-wrapper__link-to-current-trades"
					>
						<img src="/img/icons/left-icon.svg" alt="" />
						Текущие торги
					</Link>
				)}
				<div className="car-info car-info-general">
					<Slider
						photos={carPhotos}
						isMobile={isMobile}
						imgAlt="Автомобиль"
						onImageClick={(index) => {
							setStartIndex(index);
							setShowGallery(true);
						}}
					/>
					{showGallery && (
						<Gallery
							startIndex={startIndex}
							showPlayButton={false}
							items={carPhotos}
							onClose={() => setShowGallery(false)}
							isWithOverlay={true}
							showFullscreenButton={true}
							showThumbnails={true}
						/>
					)}
					<div className="single-car-wrapper__general-info">
						<div className="single-car-wrapper__general-info-header">
							<div className="car-name">
								{carSaleInfo?.display_name}, {carSaleInfo?.car?.year}
							</div>
							{!isMobile && elEditButton()}
							{auction?.sellerable?.logo?.url && (
								<div className="single-car-wrapper__logoSeller">
									<LogoSeller image={auction?.sellerable?.logo?.url} />
								</div>
							)}
						</div>
						<div className="d-flex gap-5px">
							{elActions()}
							{carSaleInfo?.inspection_type?.name && (
								<div
									className={clsx(
										"car-checks__automart",
										`car-checks__automart_${carSaleInfo.inspection_type.id}`
									)}
								>
									<img
										className="car-checked-icon"
										src={
											carSaleInfo.inspection_type.id === "seller"
												? "/img/icons/reviewed-seller.svg"
												: "/img/icons/reviewed-automart.svg"
										}
										alt=""
									/>
									{carSaleInfo.inspection_type.name}
								</div>
							)}
						</div>
						<CarSummary
							carSaleInfo={carSaleInfo}
							isMobile={isMobile}
							lot={lot}
							isLotSale={isLotSale}
							id={id}
							elEditButton={elEditButton}
							responsible={auction?.responsible}
						/>
					</div>
				</div>
				{lot?.id && <CarLotComment auctionId={auctionId} lotId={lot?.id} />}
				<CarTabsInfo
					carSaleInfo={{ ...carSaleInfo, fields, photos }}
					defects={defects}
					id={id}
					isLotSale={isLotSale}
					isLotActive={lot?.is_active}
					isMobile={isMobile}
				/>
				{isLotSale && (
					<CarLotInfo auction={auction} lot={lot} isMobile={isMobile} />
				)}
			</div>
			{showTransitionPopup && (
				<TransitionConfirmPopup
					handleClose={() => setShowTransitionPopup(false)}
					transition={showTransitionPopup}
					auctionId={carSaleInfo?.reserved_for?.auction?.id || auctionId}
					lotId={carSaleInfo?.reserved_for?.id || lotId}
					changeStatusLot={changeStatusCar}
					isCar={true}
				/>
			)}
		</>
	) : null;
});
