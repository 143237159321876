import React, { useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { getCarsList } from "@api/cars";
import { meStore } from "@mobx/me";
import { NO_PERMISSION_URL } from "@utils/common";
import { LotChangeView } from "@components/lot-change-view/lot-change-view";
import { Pagination } from "@components/pagination/pagination";
import { Table } from "@components/table/table";
import { Button } from "@components/button/button";

import { CarsItem } from "../cars-item";
import { getColumns } from "../utils";

import "../cars.scss";
import Filter from "@components/filters/Filter/Filter";
import { dictionariesStore } from "@mobx/dictionaries";
import { getDictionaries } from "@api/dictionaries";
import { citiesStore } from "@mobx/cities";
import FilterButton from "@components/filters/FilterButton/FilterButton";

const PER_PAGE = [25, 50, 100, 250, 500];

const FORM_RESET_TYPES = [
	"filters[car][brand_ids][]",
	"filters[car][model_ids][]",
	"filters[car][engine_id]",
	"filters[car][body_id]"
];

const FORM_TYPES = {
	"filters[car][brand_ids][]": "car_brands",
	"filters[car][engine_id]": "car_fuels",
	"filters[car][body_id]": "car_bodies"
};

export const CarsTab = observer(() => {

	let [searchParams, setSearchParams] = useSearchParams();
	const [isInitLoading, setIsInitLoading] = useState(false);

	const [filterData, setFilterData] = useState([
		{
			id: 0,
			type: "select",
			name: "filters[car][type_id]",
			placeholder: "Категория Т/С",
			options: [],
			value: [],
			className: "red"
		},
		{
			id: 1,
			type: "select",
			name: "filters[car][brand_ids][]",
			placeholder: "Марка",
			options: [],
			value: [],
			disabled: true
		},
		{
			id: 2,
			type: "select",
			name: "filters[car][model_ids][]",
			placeholder: "Модель",
			options: [],
			value: [],
			reset: false,
			disabled: true
		},
		{
			id: 3,
			type: "select",
			name: "filters[car][engine_id]",
			placeholder: "Тип двигателя",
			options: [],
			value: [],
			disabled: true
		},
		{
			id: 4,
			type: "select",
			name: "filters[car][body_id]",
			placeholder: "Тип кузова",
			options: [],
			value: [],
			disabled: true
		},
		{
			id: 5,
			type: "text",
			name: "filters[car][vin]",
			placeholder: "VIN"
		},
		{
			id: 6,
			type: "select",
			name: "filters[owner_type]",
			placeholder: "Тип продавца",
			options: [{ label: "Физическое лицо", value: "user" }, { label: "Компания", value: "company" }],
			value: []
		},
		// {
		// 	id: 2,
		// 	type: 'select',
		// 	name: 'filters[department_ids][]',
		// 	placeholder: 'Компании или подразделения',
		// 	options: []
		// },
		{
			id: 7,
			type: "select",
			name: "filters[status_id]",
			placeholder: "Cтатус состояния т/с",
			options: [],
			value: []
		},
		{
			id: 8,
			type: "text",
			name: "filters[reg_number]",
			placeholder: "Гос номер"
		},
		{
			id: 9,
			type: "select",
			name: "filters[area_ids][]",
			isMulti: true,
			placeholder: "Регион",
			options: [],
			value: []
		},
		{
			id: 10,
			type: "select",
			name: "filters[city_ids][]",
			isMulti: true,
			placeholder: "Город",
			options: [],
			value: []
		},
		{
			id: 11,
			type: "number",
			name: "filters[car][year_gt]",
			placeholder: "Год от",
			min: 1900,
			max: new Date().getFullYear()
		},
		{
			id: 12,
			type: "number",
			name: "filters[car][year_ls]",
			placeholder: "Год до",
			min: 1900,
			max: new Date().getFullYear()
		},
		{
			id: 13,
			type: "select",
			name: "filters[location_id]",
			placeholder: "Местонахождение",
			options: [],
			value: []
		},
		// {
		// 	id: 13,
		// 	type: 'date',
		// 	name: 'filters[<created_at]',
		// 	placeholder: 'Cостояние ТС'
		// },
		{
			id: 14,
			type: "number",
			name: "filters[mileage_gt]",
			placeholder: "Пробег от"
		},
		{
			id: 15,
			type: "number",
			name: "filters[mileage_ls]",
			placeholder: "Пробег до"
		},
		{
			id: 16,
			type: "select",
			name: "filters[car][transmission_id]",
			placeholder: "КПП",
			options: [],
			value: []
		},
		{
			id: 17,
			type: "select",
			name: "filters[color_ids][]",
			placeholder: "Цвет",
			isMulti: true,
			options: [],
			value: []
		},
		{
			id: 18,
			type: "number",
			name: "filters[car][engine_volume_gt]",
			placeholder: "Объем от"
		},
		{
			id: 19,
			type: "number",
			name: "filters[car][engine_volume_ls]",
			placeholder: "Объем до"
		},
		// {
		// 	id: 21,
		// 	type: 'date',
		// 	name: 'filters[<created_at]',
		// 	placeholder: 'наличие НДС'
		// },
		{
			id: 20,
			type: "select",
			name: "filters[inspection]",
			placeholder: "Осмотрено",
			options: [],
			value: []
		},
		{
			id: 21,
			type: "select",
			name: "filters[car][drive_id]",
			placeholder: "Привод",
			options: [],
			value: []
		},
		// {
		// 	id: 25,
		// 	type: 'date',
		// 	name: 'filters[gt_last_active_at]',
		// 	placeholder: 'Обивка салона'
		// },
		{
			id: 22,
			type: "search",
			name: "filters[company_ids][]",
			placeholder: "Компании",
			typeId: 2
		}
	]);

	const [isReadyFilter, setIsReadyFilter] = useState(false);
	const [formData, setFormData] = useState({});

	const [filterShow, setFilterShow] = useState(false);

	const [carType, setCarType] = useState("");

	const {
		car_status,
		car_brands,
		car_drives,
		car_colors,
		inspection_types,
		car_transmissions,
		areas,
		car_locations,
		car_types
	} = dictionariesStore.dictionaries;
	const { cities } = citiesStore;

	const [cars, setCars] = useState();
	const [view, setView] = useState();

	const columns = getColumns(meStore.hasPermission("admin.sales.view"));

	const requestCars = (params, filters) => {
		getCarsList({ ...params, ...filters }).then(setCars);
	};

	if (meStore.noPermission("admin.sales.view") && meStore.noPermission("sales.view")) {
		return <Navigate to={NO_PERMISSION_URL} />;
	}

	if (meStore.data === null) {
		return null;
	}

	useEffect(() => {

		(async () => {
			if (!car_status?.length) await dictionariesStore.request("status", {
				filters: { entity: "sale" },
				saveKey: "car_status"
			});
			if (!car_locations?.length) await dictionariesStore.request("car_locations");
			if (!citiesStore.cities?.length) await citiesStore.fetch();
			if (!areas?.length) await dictionariesStore.request("areas");
			if (!car_types?.length) await dictionariesStore.request("car_types");
			if (!car_drives?.length) await dictionariesStore.request("car_drives");
			if (!car_drives?.length) await dictionariesStore.request("car_drives");
			if (!car_colors?.length) await dictionariesStore.request("car_colors");
			if (!inspection_types?.length) await dictionariesStore.request("inspection_types");
			if (!car_transmissions?.length) await dictionariesStore.request("car_transmissions");

			let carStatus = await getDictionaries("car_types");
			setFilterData(filterData.map(x => {
				if (x.name === "filters[city_ids][]") x.options = citiesStore.cities.map(x => ({
					value: x.id,
					label: x.name
				}));
				if (x.name === "filters[status_id]") x.options = dictionariesStore.dictionaries?.car_status;
				if (x.name === "filters[area_ids][]") x.options = dictionariesStore.dictionaries?.areas;
				if (x.name === "filters[location_id]") x.options = dictionariesStore.dictionaries?.car_locations;
				if (x.name === "filters[car][type_id]") x.options = carStatus.map(x => ({
					value: x.id,
					label: x.name,
					type: x.key
				}));
				if (x.name === "filters[car][drive_id]") x.options = dictionariesStore.dictionaries?.car_drives;
				if (x.name === "filters[color_ids][]") x.options = dictionariesStore.dictionaries?.car_colors;
				if (x.name === "filters[inspection]") x.options = dictionariesStore.dictionaries?.inspection_types;
				if (x.name === "filters[car][transmission_id]") x.options = dictionariesStore.dictionaries?.car_transmissions;
				return x;
			}));

			setIsReadyFilter(true);
		})();

	}, []);

	const elEditBtn = () => {
		return (meStore.hasPermission("admin.sales.edit") || meStore.hasPermission("sales.edit")) && (
			<Button to="/car/create" size="m" className="d-flex align-items-center gap-5px">
				<img srcSet="/img/icons/plus-icon.svg" alt="" />
				{(meStore.noPermission("admin.sales.edit") && meStore.hasPermission("sales.edit"))
					? "Добавить"
					: "Добавить вручную"}
			</Button>
		);
	};

	const elViewSettings = () => {
		return (
			<div className="auctions-wrapper">
				<div className="d-flex align-items-center justify-content-between">
					<div className="title">
						<span>Автомобили</span>
					</div>
					<div>
						<div className="d-flex">
							<LotChangeView setView={setView} nameView="cars_view" />
							{elEditBtn()}
						</div>
					</div>
				</div>
			</div>
		);
	};

	const changeSelectFilter = async (key, type, data, reset) => {

		let array = filterData.map(x => x);

		if (type === "select") {

			if (key === "filters[car][type_id]" && !data[0]?.value) {

				array = array.map(x => {
					if (FORM_RESET_TYPES.includes(x.name)) {
						x.options = [];
						x.disabled = true;
						x.reset = !x.reset;
					}
					return x;
				});
			}

			if (key === "filters[car][brand_ids][]" && !data[0]?.value) {
				array = array.map(x => {
					if (x.name === "filters[car][model_ids][]") {
						x.options = [];
						x.disabled = true;
						x.reset = !x.reset;
					}
					return x;
				});
			}

			if (key === "filters[car][type_id]" && data[0]?.value) {
				let type = data[0].type;
				setCarType(type);
				for (let key in FORM_TYPES) {
					await dictionariesStore.request(FORM_TYPES[key], { filters: { car_type_key: type } });
				}

				array = array.map(x => {
					if (FORM_TYPES.hasOwnProperty(x.name)) {
						x.options = dictionariesStore.dictionaries[FORM_TYPES[x.name]];
						x.disabled = false;
						if (reset) x.reset = !x.reset;
					}
					return x;
				});
			}
			if (key === "filters[car][brand_ids][]" && data[0]?.value) {
				let parse = JSON.parse(sessionStorage.getItem("filter:cars:data"));
				let type = reset ? carType : parse["filters[car][type_id]"][0].type;
				await dictionariesStore.request("car_models", {
					filters: {
						car_brand_id: data[0]?.value,
						car_type_key: type
					}
				});

				array = array.map(x => {
					if (x.name === "filters[car][model_ids][]") {
						x.options = dictionariesStore.dictionaries?.car_models;
						x.disabled = false;
						if (reset) x.reset = !x.reset;
					}
					return x;
				});
			}
			if (key === "filters[owner_type]") {
				array = array.map(x => {
					if (x.name === "filters[company_ids][]" && data[0]?.value === "user") {
						x.name = "filters[owner_ids][]";
						x.placeholder = "ФИО";
						x.typeId = 1;
					} else if (x.name === "filters[owner_ids]" && data[0]?.value === "company") {
						x.name = "filters[company_ids][][]";
						x.placeholder = "Компании";
						x.typeId = 2;
					}
					return x;
				});
			}
		}

		setFilterData(array);
	};

	const onInitSelectField = async (key, type, data) => {
		changeSelectFilter(key, type, data);
	};

	const onChangeSelectField = async (key, type, data) => {
		changeSelectFilter(key, type, data, true);
	};

	const onPagination = ({ page, per_page }) => {
		setSearchParams({ tab: "cars", page: page });
		requestCars({ page: page, per_page: cars?.per_page }, formData);
	};

	const onSubmit = (data) => {
		setFormData(data);
		isInitLoading && setSearchParams({ tab: "cars" });
		requestCars({
			page: !isInitLoading ? searchParams.get("page") : 1,
			per_page: cars?.per_page || PER_PAGE[0]
		}, data);
		setIsInitLoading(true);
		setFilterShow(false);
	};

	const onResetFilter = () => {
		setFilterData(filterData.map(x => {
			if (FORM_RESET_TYPES.includes(x.name)) {
				x.options = [];
				x.disabled = true;
			}
			if (x.type === "search") {
				x.name = "filters[company_ids][]";
				x.typeId = 2;
				x.placeholder = "Компании";
			}
			return x;
		}));
		setFilterShow(false);
	};

	const onCloseFilter = () => {
		setFilterShow(false);
	};

	return (
		<div>
			<div className="car-sales__content-head">
				{isReadyFilter &&
					<Filter
						data={filterData}
						typeFilter={"filter:cars"}
						typeFilterData={"filter:cars:data"}
						onSubmit={onSubmit}
						indent
						mobileShow={filterShow}
						onInitSelectField={onInitSelectField}
						onChangeSelectField={onChangeSelectField}
						onReset={onResetFilter}
						onClose={onCloseFilter}
					/>
				}
				{elViewSettings()}
				<FilterButton indent onClick={() => setFilterShow(true)} />
			</div>
			{cars ? (
				<>
					<div className="mt-3 d-flex align-items-stretch flex-wrap gap-10px">
						{view === "tile" ? cars.data?.map((car) => <CarsItem car={car}
																			 key={`car-sale-${car.id}`} />) : (
							<Table
								data={cars.data}
								columns={columns}
							/>
						)}
					</div>
					<Pagination
						data={cars}
						onChange={onPagination}
						perPageList={PER_PAGE}
					/>
				</>
			) : (
				"Данных нет"
			)}
		</div>
	);
});
